// ... Battle of layouts
import { DocTimelineDraw, DocTimelineLayout } from './types'
import orderBy from 'lodash-es/orderBy'

function isDocOverlapping(doc: DocTimelineDraw, doc2: DocTimelineDraw) {
  return (
    doc.draw.x <= doc2.draw.x + doc2.draw.width &&
    doc.draw.x + doc.draw.width >= doc2.draw.x
  )
}

export function simpleDocsLayout(docs: DocTimelineDraw[]): DocTimelineLayout[] {
  const colDocs: Record<string, DocTimelineLayout[]> = {}
  const layoutDocs: DocTimelineLayout[] = []

  docs.forEach((doc, i) => {
    let col = 0
    while (
      (colDocs[col] ?? []).some((colDoc) => isDocOverlapping(doc, colDoc))
    ) {
      col++
    }
    if (!colDocs[col]) {
      colDocs[col] = []
    }
    const layoutDoc: DocTimelineLayout = {
      ...doc,
      layoutRow: col,
    }
    colDocs[col].push(layoutDoc)
    layoutDocs.push(layoutDoc)
  })

  return layoutDocs
}

export function biggerFirstDocsLayout(
  docs: DocTimelineDraw[]
): DocTimelineLayout[] {
  const colDocs: Record<string, DocTimelineLayout[]> = {}
  const layoutDocs: DocTimelineLayout[] = []

  const biggerFirstDocs = orderBy(docs, ['draw.width'], ['desc'])

  biggerFirstDocs.forEach((doc, i) => {
    let col = 0
    while (
      (colDocs[col] ?? []).some((colDoc) => isDocOverlapping(doc, colDoc))
    ) {
      col++
    }
    if (!colDocs[col]) {
      colDocs[col] = []
    }
    const layoutDoc: DocTimelineLayout = {
      ...doc,
      layoutRow: col,
    }
    colDocs[col].push(layoutDoc)
    layoutDocs.push(layoutDoc)
  })

  return layoutDocs
}

export {
  // NOTE: Change this to choose the best!
  biggerFirstDocsLayout as bestDocsLayout,
}
