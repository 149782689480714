import { MagnifyingGlassMinus, MagnifyingGlassPlus } from 'phosphor-react'
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import ReactHammer from 'react-hammerjs'
import styles from './ZoomAndPanMedia.module.css'

// NOTE: Avoid break my SSR for this s****y number lol
// https://github.com/hammerjs/hammer.js/blob/master/src/inputjs/input-consts.js
const HAMMER_DIRECTION_ALL = 30

interface ZoomAndPanMediaProps {
  src: string
  isModal?: boolean
}

const PINCH_TIMEOUT = 300

export default function ZoomAndPanMedia({
  src,
  isModal,
}: ZoomAndPanMediaProps) {
  const [state, setState] = useState({
    zoom: 1,
    pinchingZoom: 1,
    lastPinchedAt: 0,
    deltaX: 0,
    deltaY: 0,
    panDeltaX: 0,
    panDeltaY: 0,
    maxWidth: 0,
    maxHeight: 0,
  })

  const mediaContentRef = useRef<HTMLDivElement>(null)

  const boundsDeltas = useCallback(
    (deltaX: number, deltaY: number, zoom: number) => {
      const maxDeltaX = (state.maxWidth * (+zoom - 1)) / 2
      const maxDeltaY = (state.maxHeight * (+zoom - 1)) / 2

      const boundedDeltaX = Math.max(Math.min(deltaX, maxDeltaX), -maxDeltaX)
      const boundedDeltaY = Math.max(Math.min(deltaY, maxDeltaY), -maxDeltaY)

      return {
        deltaX: boundedDeltaX,
        deltaY: boundedDeltaY,
      }
    },
    [state.maxHeight, state.maxWidth]
  )

  function getTransform() {
    const translateDeltaX = state.deltaX + state.panDeltaX
    const translateDeltaY = state.deltaY + state.panDeltaY
    const scale = state.zoom * state.pinchingZoom
    return `translate(${translateDeltaX}px, ${translateDeltaY}px) scale(${scale})`
  }

  const handleZoom = useCallback(
    (param: number) => {
      setState((prevState) => {
        const nextZoom =
          param + prevState.zoom > 4 || param + prevState.zoom < 1
            ? prevState.zoom
            : param + prevState.zoom
        return {
          ...prevState,
          pinchingZoom: 1,
          zoom: nextZoom,
          ...boundsDeltas(prevState.deltaX, prevState.deltaY, nextZoom),
        }
      })
    },
    [boundsDeltas]
  )

  const handlePinchEnd = useCallback(
    (e: any) => {
      let newZoom = state.zoom * e.scale
      newZoom = Math.max(Math.min(newZoom, 4), 1)
      setState({
        ...state,
        pinchingZoom: 1,
        lastPinchedAt: new Date().getTime(),
        zoom: newZoom,
        ...boundsDeltas(state.deltaX, state.deltaY, newZoom),
      })
    },
    [boundsDeltas, state]
  )

  const handleWheel = useCallback(
    (e: any) => {
      e.preventDefault()
      handleZoom(e.deltaY * -0.01)
    },
    [handleZoom]
  )

  function resetZoom() {
    const zoom = 1
    setState({
      ...state,
      pinchingZoom: 1,
      zoom,
      ...boundsDeltas(state.deltaX, state.deltaY, zoom),
    })
  }

  const handlePinch = useCallback(
    (e: HammerInput) => {
      const pinchingZoom = +e.scale
      setState({
        ...state,
        pinchingZoom,
      })
    },
    [state]
  )

  function handlePan(e: HammerInput) {
    if (
      state.pinchingZoom !== 1 ||
      new Date().getTime() - state.lastPinchedAt < PINCH_TIMEOUT
    ) {
      return
    }
    setState({
      ...state,
      panDeltaX: +e.deltaX,
      panDeltaY: +e.deltaY,
    })
  }

  function handlePanEnd(e: HammerInput) {
    if (
      state.pinchingZoom !== 1 ||
      new Date().getTime() - state.lastPinchedAt < PINCH_TIMEOUT
    ) {
      return
    }

    let deltaX = +e.deltaX + state.deltaX
    let deltaY = +e.deltaY + state.deltaY

    setState({
      ...state,
      panDeltaX: 0,
      panDeltaY: 0,
      ...boundsDeltas(deltaX, deltaY, state.zoom * state.pinchingZoom),
    })
  }

  function onLoadImage(e: SyntheticEvent<HTMLImageElement, Event>) {
    setState({
      ...state,
      maxWidth: e.currentTarget.width,
      maxHeight: e.currentTarget.height,
    })
  }

  useEffect(() => {
    const image = mediaContentRef.current
    if (image) {
      image.addEventListener('wheel', handleWheel, {
        passive: false,
      })
      return () => image.removeEventListener('wheel', handleWheel)
    }
  }, [handleWheel])

  const [fullpage, setFullPage] = useState(false)

  return (
    <>
      <div className={styles.ZoomAndPanMedia}>
        <div className={styles.ZoomAndPanMediaContainer} ref={mediaContentRef}>
          <ReactHammer
            options={{
              recognizers: {
                pan: {
                  enable: true,
                  direction: HAMMER_DIRECTION_ALL,
                },
                pinch: {
                  enable: true,
                },
              },
            }}
            onPinch={handlePinch}
            onPinchEnd={handlePinchEnd}
            onPan={handlePan}
            onPanEnd={handlePanEnd}
          >
            <img
              alt="Zoom and pan"
              onDragStart={(e) => {
                e.preventDefault()
                return false
              }}
              onLoad={onLoadImage}
              draggable="false"
              style={{ transform: getTransform() }}
              className={styles.Zoomable}
              src={src}
            />
          </ReactHammer>
        </div>
        <div
          className={
            isModal
              ? styles.ZoomAndPanMediaControlsModal
              : styles.ZoomAndPanMediaControls
          }
        >
          <div className="d-flex flex-row mb-2">
            <div
              className={`${styles.ZoomButton} pointer btn-zoom mb-2`}
              onClick={() => handleZoom(-0.1)}
            >
              <MagnifyingGlassMinus size={25} />
            </div>
            <div
              className={`${styles.ZoomResetButton} pointer btn-zoom mb-2`}
              onClick={() => resetZoom()}
            >
              Reset
            </div>
            <div
              className={`${styles.ZoomButton} pointer btn-zoom mb-2`}
              onClick={() => handleZoom(0.1)}
            >
              <MagnifyingGlassPlus size={25} />
            </div>
            {/* <div
              onClick={() => setFullPage(true)}
              className="mb-2 ms-3 pointer d-block d-md-none"
            >
              Full
            </div> */}
          </div>
        </div>
        {fullpage && (
            <div
              className={styles.fullpage}
              style={{ backgroundImage: `url(${src})` }}
            ></div>
          )}
      </div>
    </>
  )
}
