import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap'
import { DOCUMENT_TYPES_LABELS } from '../../consts'
import { Doc } from '../../types'
import { smartSlug } from '../../utils/slug'
import DocItem, { DocTypeIcon, DocTypeIconButton } from '../DocItem/DocItem'
import LangLink from '../LangLink'
import styles from './DocDetailInfo.module.css'

interface DocDetailProps {
  doc: Doc
}

export default function DocDetailInfo({ doc }: DocDetailProps) {
  const { i18n, t } = useTranslation()
  const [searchParams] = useSearchParams()

  return (
    <div className={`${styles.DocDetailInfo} w-100`}>
      <div className={`${styles.HeaderDoc}`}>
        <h2 className={styles.Title}>{doc.title[i18n.language]}</h2>
        <h5 className="ms-2">
          <DocTypeIconButton typeDocument={doc.content_type} />
        </h5>
      </div>
      <div>
        <div
          className={styles.Description}
          dangerouslySetInnerHTML={{ __html: doc.description[i18n.language] }}
        />
      </div>
      <div className="mt-3">
        <div className={styles.InfoContainer}>
          <div className={styles.LabelWord}>Categoria</div>
          <div className={styles.ContentInfo}>
            {t(DOCUMENT_TYPES_LABELS[doc.document_type])}
          </div>
        </div>
        {doc.date && (
          <div className={styles.InfoContainer}>
            <div className={styles.LabelWord}>Periodo</div>
            <div className={styles.ContentInfo}>
              {dayjs(doc.date).format('DD/MM/YYYY')}{' '}
              {doc.date_to && ' - ' + dayjs(doc.date_to).format('DD/MM/YYYY')}
            </div>
          </div>
        )}
        
        {doc.document_type === 'film' || doc.document_type === 'video_interview' ? (
          <>
            {doc.data.author && (
              <div className={styles.InfoContainer}>
                <div className={styles.LabelWord}>Autore</div>
                <div className={styles.ContentInfo}>{doc.data.author}</div>
              </div>
            )}
            {doc.data.archive && (
              <div className={styles.InfoContainer}>
                <div className={styles.LabelWord}>Archivio</div>
                <div className={styles.ContentInfo}>{doc.data.archive}</div>
              </div>
            )}
            {doc.data.duration && (
              <div className={styles.InfoContainer}>
                <div className={styles.LabelWord}>Durata</div>
                <div className={styles.ContentInfo}>{doc.data.duration}</div>
              </div>
            )}
            {doc.data.format && (
              <div className={styles.InfoContainer}>
                <div className={styles.LabelWord}>Formato</div>
                <div className={styles.ContentInfo}>{doc.data.format}</div>
              </div>
            )}
          </>
        ): (
          <>
            {doc.data.author && (
              <div className={styles.InfoContainer}>
                <div className={styles.LabelWord}>Autore</div>
                <div className={styles.ContentInfo}>{doc.data.author}</div>
              </div>
            )}
            {doc.data.archive && (
              <div className={styles.InfoContainer}>
                <div className={styles.LabelWord}>Archivio</div>
                <div className={styles.ContentInfo}>{doc.data.archive}</div>
              </div>
            )}
          </>
        )}
        {doc.keywords.length > 0 && (
          <div className={styles.InfoContainer}>
            <div className={styles.LabelWord}>Keywords</div>
            <div className={styles.ContentInfo}>{doc.keywords.join(', ')}</div>
          </div>
        )}
      </div>
      {doc.related_documents.length > 0 && (
        <div className={styles.MaterialiCorrelati}>
          <div className={styles.ContainerMateriali}>
            <div className={styles.TitleMateriali}>
              {t('materiali_correlati')}
            </div>
          </div>
          {doc.related_documents.map((doc) => (
            <LangLink
              key={doc.id}
              className={'no-link'}
              to={`/document/${smartSlug(doc.id, doc.title[i18n.language])}?from_materiale_collegato`}
            >
              <div className={styles.DocMateriale}>
                {doc.image_thumb && (
                  <div
                    className={styles.BlockImageMateriale}
                    style={{
                      backgroundImage: `url(${doc.image_thumb})`,
                    }}
                  ></div>
                )}
                <div className={styles.InfoMaterialeCollegato}>
                  <div className="d-flex align-items-center">
                    <div>
                      <DocTypeIcon typeDocument={doc.content_type} />
                    </div>
                    <div className="ms-2">
                      {doc.date
                        ? dayjs(doc.date)
                            .locale(i18n.language)
                            .format('DD MMMM YYYY')
                        : doc.date_to
                        ? dayjs(doc.date_to)
                            .locale(i18n.language)
                            .format('DD MMMM YYYY')
                        : ''}
                    </div>
                  </div>
                  <div className="mt-2">{doc.title[i18n.language]}</div>
                </div>
              </div>
            </LangLink>
          ))}
        </div>
      )}
    </div>
  )
}
