import classNames from 'classnames'
import dayjs from 'dayjs'
import Select from 'react-select'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import ArchiveTimeBrush from '../../components/ArchiveTimeBrush'
import DocItem from '../../components/DocItem'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import SuggestionAutocomplete from '../../components/SuggestionAutocomplete'
import { FacetRecord, useDocsFacets, useInfiniteDocs } from '../../hooks/docs'
import { useBlockBodyScroll, useIsMobileScreen } from '../../hooks/screen'
import { Doc } from '../../types'
import { createColGenerator } from '../../utils/col'
import { smartSlug } from '../../utils/slug'
import { useQsFilters } from '../../hooks/filters'
import styles from './Archive.module.css'
import { Offcanvas, OffcanvasBody } from 'reactstrap'
import { scaleLinear } from 'd3-scale'
import { extent, max } from 'd3-array'
import AutoTipModal from '../../components/AutoTipModal'
import { useContent } from '../../hooks/contents'

const getFilters = (params: URLSearchParams) => ({
  search: params.get('search') ?? '',
  document_type: params.getAll('document_type') ?? '',
  content_type: params.getAll('content_type') ?? '',
  keywords: params.getAll('keywords') ?? '',
  from_date: params.get('from_date') ?? '',
  to_date: params.get('to_date') ?? '',
  ordering: params.get('ordering') ?? '-date',
})

interface FiltersBlockProps {
  facets: {
    document_type: FacetRecord
    keywords: FacetRecord
    date_to__year: FacetRecord
    content_type: FacetRecord
  }
  filters: Record<string, any>
  onFiltersChange(filters: Record<string, any>): void
  documentTypes: string[]
  contentTypes: string[]
  isMobileScreen: boolean
  handleClose: () => void
}

const defaultFilters = {
  search: '',
  document_type: [],
  content_type: [],
  keywords: [],
  from_date: '',
  to_date: '',
  ordering: '-date',
}

function FiltersBlock({
  facets,
  filters,
  onFiltersChange,
  documentTypes,
  handleClose,
  isMobileScreen,
}: FiltersBlockProps) {
  const [filtersState, setFiltersState] = useState({
    document_type: filters.document_type ?? [],
    search: filters.search ?? '',
    content_type: filters.content_type ?? [],
    keywords: filters.keywords ?? [],
    ordering: filters.ordering ?? '-date',
    from_date: filters.from_date ?? '',
    to_date: filters.to_date ?? '',
  })

  const fromYear = filtersState.from_date
    ? dayjs(filtersState.from_date).year()
    : null
  const toYear = filtersState.to_date
    ? dayjs(filtersState.to_date).year()
    : null

  console.log(fromYear, toYear)

  const years = useMemo(
    () => Object.keys(facets.date_to__year).filter((y) => +y > 0),
    [facets.date_to__year]
  )

  const scaleYears = useMemo(() => {
    const extentData = extent(years)
    return scaleLinear().domain([+extentData[0]!, +extentData[1]! + 1])
  }, [years])

  const domain = scaleYears.domain()

  const minYear = domain[0]
  const maxYear = domain[1] - 1

  console.log(minYear, maxYear)

  const { t } = useTranslation()

  const handleYearsChange = useCallback(
    ({ fromYear, toYear }: { fromYear: number; toYear: number }) => {
      setFiltersState({
        ...filtersState,
        from_date: dayjs(new Date(fromYear, 0, 1)).format('YYYY-MM-DD'),
        to_date: dayjs(new Date(toYear, 11, 31)).format('YYYY-MM-DD'),
      })
    },
    [onFiltersChange]
  )

  useBlockBodyScroll(isMobileScreen)

  const documentTypesOptions = useMemo(
    () =>
      documentTypes.map((documentType) => ({
        value: documentType,
        label: t(documentType),
      })),
    [documentTypes, t]
  )

  const keywordsOptions = useMemo(
    () =>
      Object.keys(facets.keywords).map(
        (key) =>
          ({
            value: key,
            label: key,
          } as any)
      ),
    [facets.keywords]
  )

  useEffect(() => {
    setFiltersState({
      ...filtersState,
      search: filters.search,
      keywords: filters.keywords,
      from_date: filters.from_date,
      content_type: filters.content_type,
      document_type: filters.document_type,
      ordering: filters.ordering,
      to_date: filters.to_date,
    })
  }, [filters])

  return (
    <>
      <div
        className={`${styles.FiltersBlock} d-flex flex-column justify-content-between mx-0`}
      >
        <div
          style={{
          
          }}
        >
          <div className="col-md-12 mb-3">
            <label className="text-md">{t('search')}</label>
            <div className="mt-2">
              <SuggestionAutocomplete
                onSubmitSearch={(search) => {
                  setFiltersState((state) => ({ ...state, search: search }))
                }}
                defaultInputValue={filtersState.search}
                documentTypes={documentTypes}
              />
            </div>
          </div>
          <div className="w-100 mt-4 mt-md-0">
            <div className={styles.FiltersTitle}>
              <span className="text-md">{t('ordina_per')}</span>
            </div>
            <div>
              <select
                className="form-select mt-2"
                value={filtersState.ordering}
                onChange={(e) => {
                  setFiltersState((state) => ({
                    ...state,
                    ordering: e.target.value,
                  }))
                }}
              >
                <option value="-pk">-{t('data_inserimento')}</option>
                <option value="pk">{t('data_inserimento')}</option>
                <option value="-date">-{t('data_documento')}</option>
                <option value="date">{t('data_documento')}</option>
              </select>
            </div>
          </div>
          <div className="w-100 mt-4">
            <div className={styles.FiltersTitle}>
              <span className="text-md">
                {t('filtra_per_formato_documento')}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
              <div className={styles.DocumentType}>
                <div
                  onClick={() => {
                    if (filtersState.content_type.includes('pdf')) {
                      setFiltersState((state) => ({
                        ...state,
                        content_type: state.content_type.filter(
                          (t: string) => t !== 'pdf'
                        ),
                      }))
                    } else {
                      setFiltersState((state) => ({
                        ...state,
                        content_type: state.content_type.concat('pdf'),
                      }))
                    }
                  }}
                  className={classNames({
                    [styles.DocumentTypeSelected]:
                      filtersState.content_type.includes('pdf') ||
                      filtersState.content_type.length === 0,
                    [styles.DocumentTypeUnselected]:
                      !filtersState.content_type.includes('pdf') &&
                      filtersState.content_type.length !== 0,
                  })}
                ></div>
                <div className={styles.LabelDocumentType}>{t('pdf')}</div>
              </div>
              <div className={styles.DocumentType}>
                <div
                  onClick={() => {
                    if (filtersState.content_type.includes('image')) {
                      setFiltersState((state) => ({
                        ...state,
                        content_type: state.content_type.filter(
                          (t: string) => t !== 'image'
                        ),
                      }))
                    } else {
                      setFiltersState((state) => ({
                        ...state,
                        content_type: state.content_type.concat('image'),
                      }))
                    }
                  }}
                  className={classNames({
                    [styles.DocumentTypeSelected]:
                      filtersState.content_type.includes('image') ||
                      filtersState.content_type.length === 0,
                    [styles.DocumentTypeUnselected]:
                      !filtersState.content_type.includes('image') &&
                      filtersState.content_type.length !== 0,
                  })}
                ></div>
                <div className={styles.LabelDocumentType}>{t('image')}</div>
              </div>
              <div className={styles.DocumentType}>
                <div
                  onClick={() => {
                    if (filtersState.content_type.includes('video')) {
                      setFiltersState((state) => ({
                        ...state,
                        content_type: state.content_type.filter(
                          (t: string) => t !== 'video'
                        ),
                      }))
                    } else {
                      setFiltersState((state) => ({
                        ...state,
                        content_type: state.content_type.concat('video'),
                      }))
                    }
                  }}
                  className={classNames({
                    [styles.DocumentTypeSelected]:
                      filtersState.content_type.includes('video') ||
                      filtersState.content_type.length === 0,
                    [styles.DocumentTypeUnselected]:
                      !filtersState.content_type.includes('video') &&
                      filtersState.content_type.length !== 0,
                  })}
                ></div>
                <div className={styles.LabelDocumentType}>{t('video')}</div>
              </div>
            </div>
          </div>
          <div className="w-100 mt-4 mt-md-0">
            <div className={styles.FiltersTitle}>
              <span className="text-md">{t('filtra_per_tipo_documento')}</span>
            </div>
            <div className="mt-3 mb-3">
              <Select
                value={documentTypesOptions.filter((option) =>
                  filtersState.document_type.includes(option.value)
                )}
                closeMenuOnSelect={false}
                placeholder={t('select_document_types')}
                classNamePrefix={'keywords-select'}
                options={documentTypesOptions}
                onChange={(values) => {
                  setFiltersState((state) => ({
                    ...state,
                    document_type: values.map((v: any) => v.value),
                  }))
                }}
                isMulti
              />
            </div>
          </div>
          <div className="w-100 mt-4 mt-md-0">
            <div className={styles.FiltersTitle}>
              <span className="text-md">{t('filtra_per_keywords')}</span>
            </div>
            <div className="mt-3 mb-3">
              <Select
                value={keywordsOptions.filter((option) =>
                  filtersState.keywords.includes(option.value)
                )}
                closeMenuOnSelect={false}
                placeholder={t('select_document_types')}
                classNamePrefix={'keywords-select'}
                options={keywordsOptions}
                onChange={(values) => {
                  setFiltersState((state) => ({
                    ...state,
                    keywords: values.map((v: any) => v.value),
                  }))
                }}
                isMulti
              />
            </div>
          </div>
          <div className="w-100 mt-4 mt-md-0 h-100 d-flex flex-column">
            <div className={styles.FiltersTitle}>
              <span className="text-md">{t('filtra_per_anno')}</span>
            </div>
            <div>
              <ArchiveTimeBrush
                fromYear={fromYear}
                toYear={toYear}
                onYearsChange={handleYearsChange}
                dataYear={facets.date_to__year}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-100 px-4 d-flex justify-content-between align-items-center position-absolute py-3"
        style={{
          paddingBottom: 10,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'var(--tertiary-100)',
        }}
      >
        <div
          className="bg-100-primary pointer"
          style={{
            padding: '4px 16px',
            borderRadius: 17,
            color: 'white',
          }}
          onClick={() => {
            if (
              filtersState.from_date === minYear + '-01-01' &&
              filtersState.to_date === maxYear + '-12-31'
            ) {
              onFiltersChange({
                ...filtersState,
                from_date: '',
                to_date: '',
              })
            } else {
              onFiltersChange(filtersState)
            }

            handleClose()
          }}
        >
          {t('filter')}
        </div>
        <div
          className="pointer"
          onClick={() => {
            setFiltersState({ ...defaultFilters })
            onFiltersChange({ ...defaultFilters })
            handleClose()
          }}
        >
          {t('reset')}
        </div>
      </div>
    </>
  )
}

function isDocImageHoriz(doc: Doc) {
  if (!doc.content_metadata.width || !doc.content_metadata.height) {
    return false
  }
  return doc.content_metadata.width! > doc.content_metadata.height!
}

export interface ArchiveProps {
  documentTypes: string[]
  titleTransKey: string
}

export default function Archive({
  titleTransKey,
  documentTypes,
}: ArchiveProps) {
  const { t, i18n } = useTranslation()
  const { uiFilters, filters, setFilters } = useQsFilters(getFilters)
  const location = useLocation()
  const [showCanvas, setShowCanvas] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const facets = useDocsFacets(
    ['document_type', 'content_type', 'keywords', 'date_to__year'] as const,
    {
      document_type: documentTypes,
      content_type: ['image', 'pdf', 'video', 'audio'],
    }
  )

  const filterDocType = useMemo(() => {
    if (filters.document_type.length === 0) {
      return documentTypes
    } else {
      return filters.document_type
    }
  }, [documentTypes, filters.document_type])

  const filterDocContentType = useMemo(() => {
    if (filters.content_type.length === 0) {
      return ['image', 'pdf', 'video', 'audio']
    } else {
      return filters.content_type
    }
  }, [filters.content_type])

  const {
    data: infinitDocs,
    hasNextPage,
    isLoading,
    fetchNextPage,
  } = useInfiniteDocs({
    ...filters,
    document_type: filterDocType,
    content_type: filterDocContentType,
    lang: i18n.language,
  })

  const isMobileScreen = useIsMobileScreen(true)

  const getDesktopCol = createColGenerator(4)
  const getMobileCol = createColGenerator(1)

  const cyclesDocIds = useMemo(() => {
    return infinitDocs!.pages.reduce((all, docs) => {
      docs.results.forEach((doc) => {
        all.push(doc.id)
      })
      return all
    }, [] as number[])
  }, [infinitDocs])

  const textStories = useContent('testo-pagina-storie')

  return (
    <Layout className="min-h-100" title={t(titleTransKey)}>
      <AutoTipModal type="storie" text={textStories.text[i18n.language]} />
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className={styles.SwitchArchive}>
          <div className={styles.SwitchVisualization}>
            <LangLink to={'/stories'} className={'no-link me-3'}>
              <div className={styles.SwitchItem}>{t('themes_page_title')}</div>
            </LangLink>
            <div className={styles.SwitchItemActive}>
              {t('archive_page_title')}
            </div>
          </div>
          <div
            className={styles.InfoLabel}
            onClick={() => {
              setShowInfo(!showInfo)
            }}
          >
            {showInfo ? t('chiudi') : t('info')}
          </div>
        </div>
      </div>
      <div className={styles.TopArchive}>
        <div className={styles.Results}>
          {infinitDocs && (
            <div className={styles.ResultsNumber}>
              {infinitDocs.pages[0].count} {t('elements')}
            </div>
          )}
        </div>
        <div className={styles.Legend}>
          <div className="d-flex align-items-center">
            <div
              className={styles.LegendItem}
              style={{
                backgroundColor: 'var(--primary-100)',
              }}
            ></div>
            <div className="ms-2">{t('pdf')}</div>
          </div>
          <div className="ms-4 d-flex align-items-center">
            <div
              className={styles.LegendItem}
              style={{
                backgroundColor: 'var(--tertiary-100)',
              }}
            ></div>
            <div className="ms-2">{t('image')}</div>
          </div>
          <div className="ms-4 d-flex align-items-center">
            <div
              className={styles.LegendItem}
              style={{
                backgroundColor: 'var(--secondary-100)',
              }}
            ></div>
            <div className="ms-2">{t('video')}</div>
          </div>
        </div>

        <div
          className={styles.FilterLabel}
          onClick={() => {
            setShowCanvas(!showCanvas)
          }}
        >
          {showCanvas ? t('close_filters') : t('filter')}
        </div>
      </div>
      <div className={styles.ArchiveContainer}>
        <div className="row mb-4 ms-0 me-0">
          {infinitDocs!.pages.map((list, i) => (
            <Fragment key={i}>
              {list.results.map((doc, i) => {
                const size = isDocImageHoriz(doc) ? 1 : 1
                // Dekstop
                const desktopClass = `col-md-${getDesktopCol(size)}`
                // Mobile
                const mobileClass = `col-${getMobileCol(size)}`
                return (
                  <div
                    key={doc.id}
                    className={`${desktopClass} ${mobileClass}`}
                    style={{
                      borderRight: '1px solid #E5E5E5',
                      borderTop: '1px solid #E5E5E5',
                    }}
                  >
                    <LangLink
                      className="no-link"
                      state={{ backgroundLocation: location, cyclesDocIds }}
                      to={`/document/${smartSlug(
                        doc.id,
                        doc.title[i18n.language]
                      )}?from_archive=1`}
                      key={doc.id}
                    >
                      <DocItem doc={doc} />
                    </LangLink>
                  </div>
                )
              })}
            </Fragment>
          ))}
          {hasNextPage && !isLoading && (
            <Waypoint
              onEnter={() => {
                fetchNextPage()
              }}
            />
          )}
        </div>
      </div>
      <Offcanvas
        direction="end"
        className={styles.OffCanvas}
        // backdropClassName='bg-dark'
        isOpen={showCanvas}
        toggle={() => {
          setShowCanvas(!showCanvas)
        }}
      >
        <OffcanvasBody
          style={{
            backgroundColor: 'var(--tertiary-100)',
          }}
        >
          <FiltersBlock
            documentTypes={documentTypes}
            contentTypes={['image', 'pdf', 'video', 'audio']}
            onFiltersChange={setFilters}
            filters={uiFilters}
            facets={facets}
            handleClose={() => {
              setShowCanvas(false)
            }}
            isMobileScreen={isMobileScreen}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        className={styles.OffCanvasInfo}
        isOpen={showInfo}
        backdropClassName="offcanvas-backdrop-info"
        toggle={() => {
          setShowInfo(!showInfo)
        }}
      >
        <OffcanvasBody
          style={{
            backgroundColor: 'var(--tertiary-100)',
          }}
        >
          <div className="text-md text-100-primary">{t('informazioni')}</div>
          <div
            className="text-sm mt-2"
            dangerouslySetInnerHTML={{
              __html: textStories.text[i18n.language],
            }}
          ></div>
        </OffcanvasBody>
      </Offcanvas>
    </Layout>
  )
}
