import { CalendarDocument } from '../../types'
import bgClipInfo from '../../assets/shape-clip-home.svg'
import { ReactComponent as PlayIcon } from '../../assets/play.svg'
import styles from './FullPreviewClip.module.css'
import { useTranslation } from 'react-i18next'
import aboutImg from '../../assets/images/about-page.png'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useContent } from '../../hooks/contents'
import LangLink from '../LangLink'

interface FullPreviewClipProps {
  calendar?: CalendarDocument
}

export default function FullPreviewClip({ calendar }: FullPreviewClipProps) {
  const { i18n, t } = useTranslation()
  const aboutText = useContent('testo-pagina-team-e-contatti')
  return (
    <>
      <div
        className={classNames(styles.FullPreviewClip, {
          pointer: calendar,
        })}
        style={{
          backgroundImage: `url(${
            calendar ? calendar.document.image_preview : aboutImg
          })`,
        }}
      >
        {calendar && (
          <div className={styles.PlayIcon}>
            <PlayIcon />
          </div>
        )}
        <div
          className={styles.BlockInfoClip}
          style={{
            backgroundImage: `url(${bgClipInfo})`,
          }}
        >
          {calendar && (
            <div className={styles.ContainerFilmDelGiorno}>
              <LangLink to={'/almanac'} className="no-link">
                <div className={styles.Almanacco}>{t('almanac')}</div>
              </LangLink>
              <div className={styles.FilmDelGiorno}>{t('film_of_the_day')}</div>
            </div>
          )}
          <div>
            {calendar && (
              <div className={styles.DayClip}>
                {dayjs(calendar.date).locale(i18n.language).format('DD MMMM')}
              </div>
            )}
            <div
              style={{}}
              className={classNames(styles.TitleClip, {
                [styles.MarginTop150]: !calendar,
              })}
            >
              {calendar
                ? calendar.document.title[i18n.language]
                : aboutText.title[i18n.language]}
            </div>
          </div>
          {/* {calendar && <div className={styles.GoToClip}>{t('go_to_clip')}</div>} */}
        </div>
        <div
          style={{
            backgroundImage: `url(${bgClipInfo})`,
          }}
          className={styles.BlockInfoClipMobile}
        >
          {calendar && (
            <div className={styles.DayClip}>
              {dayjs(calendar.date).locale(i18n.language).format('DD MMMM')}
            </div>
          )}
        </div>
      </div>
      <div className={styles.BlockMobile}>
        <div className={classNames(styles.TitleClip)}>
          {calendar ? calendar.document.title[i18n.language] : t('title_about')}
        </div>
        {/* {calendar && <div className={styles.GoToClip}>{t('go_to_clip')}</div>} */}
      </div>
    </>
  )
}
