import {
  ArrowLeft,
  ArrowRight,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from 'phosphor-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Doc } from '../../types'
import styles from './DocPdf.module.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const ZOOM_SCALE_STEP = 0.2

interface DocItemProps {
  doc: Doc
  isModal?: boolean
}

export default function DocPdf({ doc, isModal }: DocItemProps) {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1)
  const zoomInScale = useCallback(
    () => setScale((s) => s + ZOOM_SCALE_STEP),
    []
  )
  const zoomOutScale = useCallback(
    () => setScale((s) => s - ZOOM_SCALE_STEP),
    []
  )
  const resetScaleZoom = useCallback(() => setScale(1), [])

  const [containerHeight, setContainerHeight] = useState<number | null>(null)
  const pdfContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (pdfContainerRef.current) {
      setContainerHeight(pdfContainerRef.current.clientHeight - 130)
    }
  }, [])

  let pdfUrl = doc.file_content
  if (process.env.NODE_ENV !== 'production' && pdfUrl) {
    const baseUrlRegex = /http(s)?:\/\/([^/]+)/
    const baseUrl = 'http://localhost:3000'
    pdfUrl = pdfUrl.replace(baseUrlRegex, baseUrl)
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  return (
    <div className={styles.InfoPdfContainer}>
      <div className={styles.PdfContainer}>
        <div ref={pdfContainerRef} className={styles.PdfDocumentContainer}>
          {containerHeight && (
            <Document
              className={styles.pdfDocument}
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                height={containerHeight}
                scale={scale}
                className={styles.pdfPage}
                pageNumber={pageNumber || 1}
              />
            </Document>
          )}
        </div>
      </div>
      {numPages && (
        <div className={`${styles.PdfControls}`}>
          <div className="d-flex align-items-center mb-2">
            <div
              className={`pointer btn-zoom me-2`}
              onClick={() => {
                if (pageNumber >= 1) {
                  setPageNumber((p) => p - 1)
                }
              }}
            >
              <ArrowLeft size={20} />
            </div>
            <div className={styles.ZoomButtonActive}>{pageNumber}</div>
            <span className={styles.ZoomButton}>{' of '}</span>
            <div className={`${styles.ZoomButton} me-2`}>{numPages}</div>
            <div
              onClick={() => {
                if (pageNumber < numPages) {
                  setPageNumber((p) => p + 1)
                }
              }}
              className="me-2 pointer"
            >
              <ArrowRight size={20} stroke={'white'} />
            </div>
          </div>
          <div className={`${styles.divisoryControl} d-block ms-3`}></div>
          <div
            className={
              isModal
                ? styles.ZoomAndPanMediaControlsModal
                : styles.ZoomAndPanMediaControls
            }
          >
            <div className="d-flex flex-row">
              <div
                className={`${styles.ZoomButton} pointer btn-zoom`}
                onClick={zoomOutScale}
              >
                <MagnifyingGlassMinus size={25} />
              </div>
              <div
                className={`${styles.ZoomResetButton} pointer btn-zoom`}
                onClick={resetScaleZoom}
              >
                Reset
              </div>
              <div
                className={`${styles.ZoomButton} pointer btn-zoom`}
                onClick={zoomInScale}
              >
                <MagnifyingGlassPlus size={25} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
