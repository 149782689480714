import dayjs from 'dayjs'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Waypoint } from 'react-waypoint'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import { useThreeMonthsCalendarDocs } from '../../hooks/calendar'
import { smartSlug } from '../../utils/slug'
import downCaret from '../../assets/down.svg'
import 'dayjs/locale/it'
import styles from './Almanac.module.css'
import AutoTipModal from '../../components/AutoTipModal'
import { useContent } from '../../hooks/contents'
import { Offcanvas, OffcanvasBody } from 'reactstrap'

export default function Almanac() {
  const { i18n, t } = useTranslation()
  const { language } = i18n
  const [activeDay, setActiveDay] = useState<string | null>(null)
  const [showInfo, setShowInfo] = useState(false)

  const { data: clips } = useThreeMonthsCalendarDocs()

  const textAlmanacco = useContent('testo-pagina-almanacco')

  // groups clips by month and day

  const clipsGroupedByMonthAndDay = useMemo(() => {
    if (!clips) return []
    return clips.reduce((acc, clip) => {
      const month = dayjs(clip.date).format('MM') as string
      const day = dayjs(clip.date).format('DD') as string
      const keyDayMonth = `${day}.${month}`
      // use keyDayMonth as key for the object
      // if the keyDayMonth does not exist, create it
      // and add the clip to the array
      // if the keyDayMonth exists, add the clip to the array
      if (!acc[keyDayMonth]) {
        acc[keyDayMonth] = []
      }
      acc[keyDayMonth].push(clip)
      return acc
    }, {} as Record<string, Record<string, any>>)
  }, [clips]) as Record<string, Record<string, any>>

  // order from December to January 31.12 -> 01.01

  const orderedClipsGroupedByMonthAndDay = useMemo(() => {
    if (!clipsGroupedByMonthAndDay) return []
    const ordered = Object.keys(clipsGroupedByMonthAndDay).sort((a, b) => {
      const aSplit = a.split('.')
      const bSplit = b.split('.')
      return Number(bSplit[1] + bSplit[0]) - Number(aSplit[1] + aSplit[0])
    })
    return ordered.reduce((acc, key) => {
      acc[key] = clipsGroupedByMonthAndDay[key]
      return acc
    }, {} as Record<string, Record<string, any>>)
  }, [clipsGroupedByMonthAndDay]) as Record<string, Record<string, any>>

  const [orderedClips, setOrderedClips] = useState(
    orderedClipsGroupedByMonthAndDay
  )


  const activeMonth = useMemo(() => {
    const replaceDotWithSlash = activeDay?.replace('.', '/')
    // write date as 2023-01-01
    // split the string and get the month
    const month = replaceDotWithSlash?.split('/')[1]
    const monthName = dayjs(`2023-${month}-01`).locale(language).format('MMMM')
    return monthName
  }, [activeDay])

  console.log(activeMonth)

  return (
    <Layout className="min-h-100" title={t('almanac')}>
      <AutoTipModal type="almanacco" text={textAlmanacco.text[i18n.language]} />
      <div className="">
        <div className={styles.TopAlmanac}>
          <div>
            <div className={styles.SwitchVisualization}>
              <div className={styles.SwitchItemActive}>{t('list')}</div>
              <LangLink to={'/calendar'} className="no-link">
                <div className={styles.SwitchItem}>{t('calendar')}</div>
              </LangLink>
              <LangLink to={'/timeline'} className="no-link">
                <div className={styles.SwitchItemThird}>{t('timeline')}</div>
              </LangLink>
            </div>
          </div>
          <div>
            <div className={styles.SwitchMonth}>
              <div
                style={{
                  opacity:
                    (activeMonth !== 'december' && activeMonth !== 'dicembre')
                      ? 1
                      : 0,
                }}
                className="pointer"
                onClick={() => {
                  // add 1 month to the current month
                  if (activeMonth === 'december' || activeMonth === 'dicembre') return
                  const active = activeDay?.split('.')
                  const month = active?.[1]
                  const day = active?.[0]
                  const date = `2023-${month}-${day}`
                  const nextMonthFirstDay = dayjs(date)
                    .add(1, 'month')
                    .startOf('month')
                    .format('DD.MM')
                  const nextMonthFirstDayElement =
                    document.getElementById(nextMonthFirstDay)
                  if (nextMonthFirstDayElement) {
                    nextMonthFirstDayElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                    })
                  }
                }}
              >
                <img
                  style={{
                    transform: 'rotate(180deg)',
                  }}
                  className="me-3 me-md-5"
                  src={downCaret}
                  alt="up"
                />
              </div>
              <div className={styles.Month}>{activeMonth}</div>
              <div
                style={{
                  opacity: 
                    (activeMonth !== 'january' && activeMonth !== 'gennaio')
                      ? 1
                      : 0,
                }}
                className="pointer"
                onClick={() => {
                  // subtract 1 month to the current month
                  if(activeMonth === 'january' || activeMonth === 'gennaio') return
                  console.log(activeMonth, 'activeMonth', activeDay)
                  const active = activeDay?.split('.')
                  const month = active?.[1]
                  const day = active?.[0]
                  const date = `2023-${month}-${day}`
                  const beforeMonthFirstDay = dayjs(date)
                    .subtract(1, 'month')
                    .startOf('month')
                    .format('DD.MM')
                  const beforeMonthFirstDayElement =
                    document.getElementById(beforeMonthFirstDay)
                  if (beforeMonthFirstDayElement) {
                    beforeMonthFirstDayElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                    })
                  }
                }}
              >
                <img className="ms-3 ms-md-5" src={downCaret} alt="down" />
              </div>
            </div>
            <div>
              <div
                className={styles.InfoLabel}
                onClick={() => {
                  setShowInfo(!showInfo)
                }}
              >
                {showInfo ? t('chiudi') : t('info')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ContainerClips}>
          {Object.keys(orderedClips).map((key, i) => {
            return (
              <LangLink
                key={i}
                className="no-link"
                to={`/almanac/${smartSlug(
                  orderedClips[key][0].id,
                  orderedClips[key][0].document.title[i18n.language]
                )}`}
              >
                <Waypoint
                  onEnter={() => {
                    // set month and year as active month year is 2023
                    if (
                      dayjs(orderedClips[key][0].date).format('DD.MM') !==
                      activeDay
                    ) {
                      setActiveDay(
                        dayjs(orderedClips[key][0].date).format('DD.MM')
                      )
                    }
                  }}
                  topOffset={'90%'}
                >
                  <div className={styles.BlockDay} id={key}>
                    <div className={styles.BlockDate}>
                      {key}
                      {orderedClips[key].length > 1 && (
                        <div
                          className={styles.PlusSign}
                          onClick={(e) => {
                            e.preventDefault()
                            // change the order of the clips in the object
                            const clips = orderedClips[key]
                            const firstClip = clips.shift()
                            clips.push(firstClip)
                            setOrderedClips({
                              ...orderedClips,
                              [key]: clips,
                            })
                          }}
                          // style as a badge
                          style={{
                            backgroundColor: 'var(--primary-100)',
                            color: 'white',

                            width: 'max-content',
                            height: 'max-content',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '12px',
                            padding: '5px',
                          }}
                        >
                          {t('cambia_clip')}
                        </div>
                      )}
                    </div>
                    <div className={styles.BlockTitle}>
                      <div className={styles.TitleMax}>
                        {orderedClips[key][0].document.title[i18n.language]}
                      </div>
                      <div>
                        {orderedClips[key][0].document.date && (
                          <span className={styles.Year}>
                            {dayjs(orderedClips[key][0].document.date).format(
                              'YYYY'
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                    {orderedClips[key][0].document.image_medium && (
                      <div className={styles.BlockImage}>
                        <img
                          src={orderedClips[key][0].document.image_medium}
                          className={'img-fluid'}
                        />
                      </div>
                    )}
                    <div className={styles.BlockDateMobile}>{key}</div>
                  </div>
                </Waypoint>
              </LangLink>
            )
          })}
          {/* <Fragment>
            {clips?.map((calendar, i) => {
              return (
                <LangLink
                  key={i}
                  className="no-link"
                  to={`/almanac/${smartSlug(
                    calendar.id,
                    calendar.document.title[i18n.language]
                  )}`}
                >
                  <Waypoint
                    onEnter={() => {
                      if (
                        dayjs(calendar.date).format('DD/MM/YYYY') !== activeDay
                      ) {
                        setActiveDay(calendar.date)
                      }
                    }}
                  >
                    <div
                      className={styles.BlockDay}
                      id={dayjs(calendar.date).format('DD/MM/YYYY')}
                    >
                      <div className={styles.BlockDate}>
                        {dayjs(calendar.date).format('DD.MM')}
                      </div>
                      <div className={styles.BlockTitle}>
                        <div className={styles.TitleMax}>
                          {calendar.document.title[i18n.language]}
                        </div>
                        <div>
                          {calendar.document.date && (
                            <span className={styles.Year}>
                              {dayjs(calendar.document.date).format('YYYY')}
                            </span>
                          )}
                        </div>
                      </div>
                      {calendar.document.image_medium && (
                        <div className={styles.BlockImage}>
                          <img
                            src={calendar.document.image_medium}
                            className={'img-fluid'}
                          />
                        </div>
                      )}
                      <div className={styles.BlockDateMobile}>
                        {dayjs(calendar.date).format('DD.MM')}
                      </div>
                    </div>
                  </Waypoint>
                </LangLink>
              )
            })}
          </Fragment> */}

          {/* {hasNextPage && !isLoading && (
            <Waypoint
              onEnter={() => {
                fetchNextPage()
              }}
            />
          )} */}
        </div>
      </div>
      <Offcanvas
        direction="end"
        className={styles.OffCanvasInfo}
        isOpen={showInfo}
        backdropClassName="offcanvas-backdrop-info"
        toggle={() => {
          setShowInfo(!showInfo)
        }}
      >
        <OffcanvasBody
          style={{
            backgroundColor: 'var(--tertiary-100)',
          }}
        >
          <div className="text-md text-100-primary">{t('informazioni')}</div>
          <div
            className="text-sm mt-2"
            dangerouslySetInnerHTML={{
              __html: textAlmanacco.text[i18n.language],
            }}
          ></div>
        </OffcanvasBody>
      </Offcanvas>
    </Layout>
  )
}
