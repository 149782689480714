import { memo, useState } from 'react'
import styles from './DocItem.module.css'
import placeholderImage from '../../assets/placeholder.png'
import { ReactI18NextChild, useTranslation } from 'react-i18next'
// import * as dayjs from 'dayjs'
import { ContentTypesUnion, Doc } from '../../types'
import dayjs from 'dayjs'
import { SpeakerSimpleHigh } from 'phosphor-react'
import shape1 from '../../assets/shape_archivio-1.svg'
import shape2 from '../../assets/shape_archivio-2.svg'
import shape3 from '../../assets/shape_archivio-3.svg'
import shape4 from '../../assets/shape_archivio-4.svg'
import shape5 from '../../assets/shape_archivio-5.svg'
import shape6 from '../../assets/shape_archivio-6.svg'

interface DocTypeIconProps {
  typeDocument: ContentTypesUnion
}

export function DocTypeIconButton({ typeDocument }: DocTypeIconProps) {
  const { t } = useTranslation()
  if (typeDocument === 'image') {
    return (
      <div className='type-document'>
        <div
          className='me-2'
          style={{
            height: 14,
            width: 14,
            borderRadius: '50%',
            backgroundColor: 'var(--tertiary-100)',
          }}
        ></div>
        <div className={styles.DocTypeIconText}>{t('image')}</div>
      </div>
    )
  } else if (typeDocument === 'pdf') {
    return (
      <div className='type-document'>
        <div
          className='me-2'
          style={{
            height: 14,
            width: 14,
            borderRadius: '50%',
            backgroundColor: 'var(--primary-100)',
          }}
        ></div>
        <div className={styles.DocTypeIconText}>{t('pdf')}</div>
      </div>
    )
  } else if (typeDocument === 'video') {
    return (
      <div className='type-document'>
        <div
          className='me-2'
          style={{
            height: 14,
            width: 14,
            borderRadius: '50%',
            backgroundColor: 'var(--secondary-100)',
          }}
        ></div>
        <div className={styles.DocTypeIconText}>{t('video')}</div>
      </div>
    )
  } else {
    return (
      <div>
        <SpeakerSimpleHigh
          weight="fill"
          color="var(--primary-100)"
          fontWeight={'bold'}
        />
        <div className={styles.DocTypeIconText}>{t('audio')}</div>
      </div>
    )
  }
}

export function DocTypeIcon({ typeDocument }: DocTypeIconProps) {
  if (typeDocument === 'image') {
    return (
      <div
        style={{
          height: 14,
          width: 14,
          borderRadius: '50%',
          backgroundColor: 'var(--tertiary-100)',
        }}
      ></div>
    )
  } else if (typeDocument === 'pdf') {
    return (
      <div
        style={{
          height: 14,
          width: 14,
          borderRadius: '50%',
          backgroundColor: 'var(--primary-100)',
        }}
      ></div>
    )
  } else if (typeDocument === 'audio') {
    return (
      <SpeakerSimpleHigh
        weight="fill"
        color="var(--primary-100)"
        fontWeight={'bold'}
      />
    )
  } else {
    return (
      <div
        style={{
          height: 14,
          width: 14,
          borderRadius: '50%',
          backgroundColor: 'var(--secondary-100)',
        }}
      ></div>
    )
  }
}

interface DocItemProps {
  doc: Doc
  className?: string
  showInfo?: boolean
  showTitle?: boolean
  customInfo?: ReactI18NextChild
}

function DocItem({
  doc,
  className,
  showInfo = true,
  showTitle = true,
  customInfo,
}: DocItemProps) {
  const imageUrl = doc.image_medium ?? placeholderImage
  const { i18n } = useTranslation()

  const [hoverItem, setHoverItem] = useState(false)

  const getRandomShape = () => {
    const shapes = [shape1, shape2, shape3, shape4, shape5, shape6]
    const random = Math.floor(Math.random() * shapes.length)
    return shapes[random]
  }

  return (
    <div
      className={`${styles.Item} ${className}`}
      onMouseEnter={() => {
        setHoverItem(true)
      }}
      onMouseLeave={() => {
        setHoverItem(false)
      }}
    >
      <div
        className={`${styles.Image} text-center`}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        {hoverItem && (
          <div
            className={styles.Shape}
            style={{ backgroundImage: `url(${getRandomShape()})` }}
          ></div>
        )}
      </div>
      <div className={styles.InfoItem}>
        <div className="text-sm d-flex align-items-center mt-3">
          <div>
            <DocTypeIcon typeDocument={doc.content_type} />
          </div>
          <div className="ms-2">
            {doc.date
              ? dayjs(doc.date).locale(i18n.language).format('YYYY')
              : doc.date_to
              ? dayjs(doc.date_to).locale(i18n.language).format('YYYY')
              : ''}
          </div>
        </div>
        {customInfo && <div>{customInfo}</div>}

        {showTitle && (
          <h5 className={'text-md mt-2'}>{doc.title[i18n.language]}</h5>
        )}
      </div>
    </div>
  )
}
export default memo(DocItem)
