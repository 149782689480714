import { useLocation } from 'react-router-dom'
import { getCookieConsentValue, CookieConsent } from 'react-cookie-consent'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import LangLink from './LangLink'

const shouldTrack = process.env.NODE_ENV === 'production'

function onInit() {
  ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)
}

function onTrack(path: string) {
  ReactGA.send({ hitType: 'pageview', page: path })
}

let hasTrackingConsent = false
// Init ASAP
if (shouldTrack && getCookieConsentValue() === 'true') {
  hasTrackingConsent = true
  onInit()
}

export default function Analytics() {
  const location = useLocation()

  useEffect(() => {
    if (hasTrackingConsent) {
      onTrack(location.pathname)
    }
  }, [location.pathname])

  return (
    <CookieConsent
      acceptOnScroll={false}
      buttonText={'Ho capito'}
      buttonClasses={'bg-100-primary text-white'}
      onAccept={() => {
        if (shouldTrack) {
          hasTrackingConsent = true
          onInit()
          onTrack(location.pathname)
        }
      }}
    >
      Questo sito utilizza i cookie per inviare statistiche anonime di
      navigazione. Leggi di più nella nostra pagina{' '}
      <LangLink to="/cookie-consent">cookie policy</LangLink>.
    </CookieConsent>
  )
}
