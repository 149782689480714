import axios from 'axios'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { ThemeDetail, ThemeWithDescription } from '../types'
import { serializeQueryParams } from './utils'

export async function getTheme(
  idOrSlug: number | string,
  signal?: AbortSignal
) {
  return (await axios.get(`/api/themes/${idOrSlug}/`, { signal }))
    .data as ThemeDetail
}

export async function getThemes(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/themes/`, {
      params: serializeQueryParams(params),
      signal,
    })
  ).data as ThemeWithDescription[]
}

export function useThemes(params: Record<string, any> = {}) {
  return useQuery(
    ['themes', params],
    ({ signal }) => getThemes(params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}

export function useTheme(idOrSlug: number | string) {
  return useQuery(['theme', idOrSlug], ({ signal }) =>
    getTheme(idOrSlug, signal)
  ).data!
}

export function prefetchTheme(client: QueryClient, idOrSlug: number | string) {
  return client.prefetchQuery(['theme', idOrSlug], ({ signal }) =>
    getTheme(idOrSlug, signal)
  )
}

export function prefetchThemes(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(['themes', params], ({ signal }) =>
    getThemes(params, signal)
  )
}
