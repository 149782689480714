import { useTranslation } from 'react-i18next'
import useMeasure from 'react-use-measure'
import Layout from '../../components/Layout'
import { useTimelineDocs } from '../../hooks/docs'
import { DocTimeline } from '../../types'
import { ScaleTime, scaleTime } from 'd3-scale'
import { extent } from 'd3-array'
import range from 'lodash-es/range'
import styles from './Timeline.module.css'
import { Fragment, memo, useCallback, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { DocTimelineLayout } from './types'
import { bestDocsLayout } from './layoutGenerator'
import Draggable from 'react-draggable'
import LangLink from '../../components/LangLink'
import { useLocation } from 'react-router-dom'
import placeholderImage from '../../assets/placeholder.png'
import { smartSlug } from '../../utils/slug'
import { useContent } from '../../hooks/contents'
import AutoTipModal from '../../components/AutoTipModal'
import { Offcanvas, OffcanvasBody } from 'reactstrap'

const TIMELINE_TOP_LEGEND_HEIGHT = 40
const TIMELINE_MARGIN_X = 30
const BRUSH_MARGIN_X = 0
const YEAR_DESKTOP_WIDTH = 250
const BRUSH_HEIGHT = 72

const DOC_BOX_MIN_WIDTH = 200
const DOC_BOX_Y_PADDING = 10
const DOC_BOX_HEIGHT = 130

function normalizeDateTime(d: Date) {
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

function normalizeDate(d: Date) {
  d.setDate(1)
  d.setMonth(0)
  normalizeDateTime(d)
  return d
}

interface TimelineDocBoxProps {
  doc: DocTimelineLayout
  cyclesDocIds: number[]
}

const TimelineDocBox = memo(({ doc, cyclesDocIds }: TimelineDocBoxProps) => {
  const location = useLocation()
  const { i18n } = useTranslation()
  return (
    <LangLink
      state={{ backgroundLocation: location, cyclesDocIds }}
      to={`/document/${smartSlug(doc.id, doc.title[i18n.language])}`}
      className={styles.TimelineDocBoxLink}
    >
      <div
        className={styles.TimelineDocBox}
        style={{
          top:
            doc.layoutRow * DOC_BOX_HEIGHT + doc.layoutRow * DOC_BOX_Y_PADDING,
          left: doc.draw.x,
          width: doc.draw.width,
          height: DOC_BOX_HEIGHT,
        }}
      >
        <div>
          <img
            src={doc.image_preview ?? placeholderImage}
            alt={doc.title[i18n.language]}
          />
        </div>
        <div className={styles.TimelineDocBoxInfo}>
          <div
            className={styles.TimelineDocBoxInfoTitle}
            title={doc.title[i18n.language]}
          >
            {doc.title[i18n.language]}
          </div>
          {/* <div
            className={styles.Description}
            dangerouslySetInnerHTML={{ __html: doc.description[i18n.language] }}
          /> */}
          <div className={styles.TimelineDocBoxInfoDate}>
            {dayjs(new Date(doc.date)).format('DD/MM/YYYY')}
          </div>
          {doc.date_to && (
            <div className={styles.TimelineDocBoxInfoDate}>
              {dayjs(new Date(doc.date_to)).format('DD/MM/YYYY')}
            </div>
          )}
        </div>
      </div>
    </LangLink>
  )
})

interface TimelineTopLegendProps {
  timelineScale: ScaleTime<number, number>
  timelineExtent: readonly [Date, Date]
}

const TimelineTopLegend = memo(
  ({ timelineExtent, timelineScale }: TimelineTopLegendProps) => {
    const width = timelineScale(timelineExtent[1]) + TIMELINE_MARGIN_X * 2
    return (
      <div
        className={styles.TimelineTopLegend}
        style={{ width, height: TIMELINE_TOP_LEGEND_HEIGHT }}
      >
        {range(
          timelineExtent[0].getFullYear(),
          timelineExtent[1].getFullYear() + 1
        ).map((year) => {
          const date = normalizeDate(new Date(`${year}-01-01`))
          const left = timelineScale(date)
          return (
            <Fragment key={year}>
              <div
                className={styles.TimelineLegendYear}
                style={{ left: left - 50 / 2, width: 50 }}
              >
                <div>{year}</div>
              </div>
              <div className={styles.TimelineLegendTick} style={{ left }} />
            </Fragment>
          )
        })}
      </div>
    )
  }
)

interface TimelineBrushLayersProps {
  docs: DocTimelineLayout[]
  scale: ScaleTime<number, number>
}

const TimelineBrushLayers = memo(
  ({ docs, scale }: TimelineBrushLayersProps) => {
    return (
      <>
        {docs.map((doc) => {
          const left = scale(new Date(doc.draw.date))
          let width = 14
          if (doc.draw.dateTo) {
            width = scale(new Date(doc.draw.dateTo)) - left
          }
          return (
            <div
              key={doc.id}
              className={styles.BrushLayer}
              style={{
                left,
                width,
                height: BRUSH_HEIGHT,
              }}
            />
          )
        })}
      </>
    )
  }
)
interface TimelineBrushProps {
  docs: DocTimelineLayout[]
  scale: ScaleTime<number, number>
  onChange(date: Date): void
}

const TimelineBrush = memo(({ docs, scale, onChange }: TimelineBrushProps) => {
  const [lineLeft, lineWidth] = useMemo(() => {
    const domain = scale.domain()
    const l = scale(domain[0])
    return [l, scale(domain[1]) - l]
  }, [scale])
  const nodeRef = useRef(null)

  const [date, setDate] = useState(() => scale.domain()[0])
  const position = useMemo(
    () => ({
      y: 0,
      x: scale(date),
    }),
    [date, scale]
  )

  return (
    <div className={styles.BrushContainer} style={{ height: BRUSH_HEIGHT }}>
      <TimelineBrushLayers docs={docs} scale={scale} />
      {/* <div
        className={styles.BrushLine}
        style={{
          top: BRUSH_HEIGHT - 15,
          left: lineLeft,
          width: lineWidth,
        }}
      />

      <Draggable
        axis="x"
        nodeRef={nodeRef}
        position={position}
        bounds={{
          left: lineLeft,
          right: lineWidth + 20,
        }}
        scale={1}
        onDrag={(_, data) => {
          setDate(scale.invert(data.x))
        }}
        onStop={(_, data) => {
          onChange(normalizeDate(scale.invert(data.x)))
        }}
      >
        <div
          ref={nodeRef}
          className={styles.BrushHandle}
          style={{
            left: -10,
            top: BRUSH_HEIGHT - 25,
          }}
        >
          <div className={styles.BrushHandleInner} />
        </div>
      </Draggable>
      <div
        className={styles.BrushCurrentYear}
        style={{
          top: BRUSH_HEIGHT - 5,
          left: lineLeft,
        }}
      >
        {date.getFullYear()}
      </div> */}
    </div>
  )
})

interface TimelineClientProps {
  width: number
  height: number
  docs: DocTimeline[]
}

function TimelineClient({ width, height, docs }: TimelineClientProps) {
  const timelineExtent = useMemo(() => {
    const [d1, d2] = extent(
      docs.reduce((all, doc) => {
        all.push(new Date(doc.date))
        if (doc.date_to) {
          all.push(new Date(doc.date_to))
        }
        return all
      }, [] as Date[])
    ) as [Date, Date]
    normalizeDate(d1)
    normalizeDate(d2)
    d2.setFullYear(d2.getFullYear() + 1)
    return [d1, d2] as const
  }, [docs])

  const yearWidth = width <= 700 ? width - 80 : YEAR_DESKTOP_WIDTH

  const timelineScale = useMemo(() => {
    return scaleTime()
      .domain(timelineExtent)
      .range([
        TIMELINE_MARGIN_X,
        (timelineExtent[1].getFullYear() - timelineExtent[0].getFullYear()) *
          yearWidth,
      ])
  }, [timelineExtent, yearWidth])

  const brushScale = useMemo(() => {
    return scaleTime()
      .domain(timelineExtent)
      .range([BRUSH_MARGIN_X, width - BRUSH_MARGIN_X])
  }, [timelineExtent, width])

  const docsDrawLayout = useMemo(() => {
    const docsDraw = docs.map((doc) => {
      const date = normalizeDateTime(new Date(doc.date))
      const x = timelineScale(date)
      let width = DOC_BOX_MIN_WIDTH
      let dateTo: Date | null = null
      if (doc.date_to) {
        dateTo = normalizeDateTime(new Date(doc.date_to))
        width = Math.max(timelineScale(dateTo) - x, width)
      }
      return {
        ...doc,
        draw: {
          dateTo,
          date,
          x,
          width,
        },
      }
    })
    const calcLayoutDocs = bestDocsLayout(docsDraw)
    return calcLayoutDocs
  }, [docs, timelineScale])

  const cyclesDocIds = useMemo(() => docs.map((d) => d.id), [docs])

  const docsRows = useMemo(() => {
    const totRows = Math.max(...docsDrawLayout.map((d) => d.layoutRow))
    return totRows
  }, [docsDrawLayout])

  const fullWidth = timelineScale(timelineExtent[1])

  const scrollRef = useRef<HTMLDivElement>(null)

  const handleDateChange = useCallback(
    (date: Date) => {
      const container = scrollRef.current
      if (container) {
        container.scrollLeft = Math.max(timelineScale(date), 0)
      }
    },
    [timelineScale]
  )

  return (
    <div className="h-100 w-100 d-flex flex-column overflow-hidden">
      <div className="flex-1 w-100 overflow-auto" ref={scrollRef}>
        <TimelineTopLegend
          timelineScale={timelineScale}
          timelineExtent={timelineExtent}
        />
        <div
          className={styles.TimelineDocsContainer}
          style={{
            width: fullWidth + TIMELINE_MARGIN_X,
            height:
              (docsRows + 1) * DOC_BOX_HEIGHT + docsRows * DOC_BOX_Y_PADDING,
          }}
        >
          {docsDrawLayout.map((docDrawLayout) => (
            <TimelineDocBox
              cyclesDocIds={cyclesDocIds}
              key={docDrawLayout.id}
              doc={docDrawLayout}
            />
          ))}
        </div>
      </div>
      <TimelineBrush
        onChange={handleDateChange}
        docs={docsDrawLayout}
        scale={brushScale}
      />
    </div>
  )
}

export default function Timeline() {
  const { t, i18n } = useTranslation()
  const { data: docs } = useTimelineDocs()
  const [showInfo, setShowInfo] = useState(false)

  const [ref, bounds] = useMeasure()

  const textAlmanacco = useContent('testo-pagina-almanacco')

  return (
    <Layout
      title={t('timeline_page_title')}
      className="h-100 w-100 overflow-hidden flex-1 d-flex flex-column"
    >
      <AutoTipModal type="almanacco" text={textAlmanacco.text[i18n.language]} />
      <div className={styles.TopAlmanac}>
        <div>
          <div className={styles.SwitchVisualization}>
            <LangLink to={'/almanac'} className="no-link">
              <div className={styles.SwitchItem}>{t('list')}</div>
            </LangLink>
            <LangLink to={'/calendar'} className="no-link">
              <div className={styles.SwitchItem}>{t('calendar')}</div>
            </LangLink>
            <div className={styles.SwitchItemActive}>{t('timeline')}</div>
          </div>
        </div>
        <div>
          <div
            className={styles.InfoLabel}
            onClick={() => {
              setShowInfo(!showInfo)
            }}
          >
            {showInfo ? t('chiudi') : t('info')}
          </div>
        </div>
      </div>
      <div
        className="h-100 w-100 overflow-hidden"
        ref={ref}
        style={{
          marginTop: 73,
        }}
      >
        {bounds.width > 0 && bounds.height > 0 && (
          <TimelineClient
            width={bounds.width}
            height={bounds.height}
            docs={docs!}
          />
        )}
      </div>
      <Offcanvas
        direction="end"
        className={styles.OffCanvasInfo}
        isOpen={showInfo}
        backdropClassName="offcanvas-backdrop-info"
        toggle={() => {
          setShowInfo(!showInfo)
        }}
      >
        <OffcanvasBody
          style={{
            backgroundColor: 'var(--tertiary-100)',
          }}
        >
          <div className="text-md text-100-primary">{t('informazioni')}</div>
          <div
            className="text-sm mt-2"
            dangerouslySetInnerHTML={{
              __html: textAlmanacco.text[i18n.language],
            }}
          ></div>
        </OffcanvasBody>
      </Offcanvas>
    </Layout>
  )
}
