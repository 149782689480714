import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import { useThemes } from '../../hooks/themes'
import { smartSlug } from '../../utils/slug'
import styles from './Themes.module.css'
import shapeSquares from '../../assets/shape_squares.svg'
import { Fragment, useState } from 'react'
import AutoTipModal from '../../components/AutoTipModal'
import { useContent } from '../../hooks/contents'
import { Offcanvas, OffcanvasBody } from 'reactstrap'

export default function Themes() {
  const { i18n, t } = useTranslation()
  const themes = useThemes()

  const textStories = useContent('testo-pagina-storie')

  const [showInfo, setShowInfo] = useState(false)

  const themesInEvidence = themes.filter((theme) => theme.in_evidence)

  return (
    <Layout className="min-h-100" title="Storie">
      <AutoTipModal type="storie" text={textStories.text[i18n.language]} />
      <div className={styles.SwitchArchive}>
        <div className={styles.SwitchVisualization}>
          <div className={`me-3 ${styles.SwitchItemActive}`}>
            {t('themes_page_title')}
          </div>
          <LangLink to={'/archive'} className={'no-link'}>
            <div className={styles.SwitchItem}>{t('archive_page_title')}</div>
          </LangLink>
        </div>
        <div
          className={styles.InfoLabel}
          onClick={() => {
            setShowInfo(!showInfo)
          }}
        >
          {showInfo ? t('chiudi') : t('info')}
        </div>
      </div>
      {themesInEvidence.length !== 1 && (
        <div className={styles.ContainerTemaInEvidenza}>
          <div className={styles.TemaInEvidenza}>{t('themes_in_evidence')}</div>
        </div>
      )}
      <div
        className={
          themesInEvidence.length === 1
            ? styles.ContainerThemes
            : styles.ContainerThemesWithoutPadding
        }
      >
        {themesInEvidence.length > 0 && (
          <div className="container-fluid">
            <div className="row mt-3 mb-4 ms-0 me-0 ms-md-2 me-md-2">
              {themesInEvidence.map((theme) => (
                <Fragment key={theme.id}>
                  {themesInEvidence.length === 1 && (
                    <div className="col-md-6">
                      <div className={styles.TemaInEvidenza}>
                        {t('theme_in_evidence')}
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames('mb-3', {
                      'col-md-6':
                        themesInEvidence.length === 2 ||
                        themesInEvidence.length === 1,
                      'col-md-4': themesInEvidence.length === 3,
                    })}
                    key={theme.id}
                  >
                    <LangLink
                      className="no-link"
                      to={`/stories/${smartSlug(
                        theme.id,
                        theme.title[i18n.language]
                      )}`}
                    >
                      <div
                        className={styles.ThemeEvidence}
                        style={{
                          backgroundImage: `url(${
                            theme.cover_image || theme.image_preview
                          })`,
                        }}
                      >
                        <div className={styles.OverlayEvidence}></div>
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                          }}
                        >
                          <img alt="share" src={shapeSquares} />
                        </div>
                        <h3 className={styles.TitleEvidence}>
                          {theme.title[i18n.language]}
                        </h3>
                        {theme.description_short && (
                          <div className={styles.DescriptionEvidence}>
                            {theme.description_short[i18n.language]}
                          </div>
                        )}
                      </div>
                      <div className={styles.ThemeEvidenceMobile}>
                        {/* <div className={styles.OverlayEvidence}></div> */}
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                          }}
                        >
                          <img alt="share" src={shapeSquares} />
                        </div>
                        <h3 className={styles.TitleEvidence}>
                          {theme.title[i18n.language]}
                        </h3>
                        <div
                          style={{
                            backgroundImage: `url(${
                              theme.cover_image || theme.image_preview
                            })`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: 250,
                          }}
                          className="d-block position-relative w-100 d-md-none mt-3 mb-3"
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: -1,
                              right: -1,
                            }}
                          >
                            <img alt="share" src={shapeSquares} />
                          </div>
                        </div>
                        {theme.description_short && (
                          <div className={styles.DescriptionEvidence}>
                            {theme.description_short[i18n.language]}
                          </div>
                        )}
                      </div>
                    </LangLink>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        className="mt-4"
        style={{
          borderTop: '1px solid #000',
        }}
      >
        <div className={`${styles.TitleThemes} pt-4 pb-4 ps-4 pe-4`}>
          {t('tutti_i_temi_e_ricerche')}
        </div>
        {themes.map((theme) => (
          <div className={styles.ContainerTheme} key={theme.id}>
            <LangLink
              className="no-link"
              to={`/stories/${smartSlug(theme.id, theme.title[i18n.language])}`}
            >
              <div className={styles.Theme}>
                {(theme.cover_image || theme.image_preview) && (
                  <img
                    alt={theme.title[i18n.language]}
                    className={styles.Image}
                    src={theme.cover_image || theme.image_preview || ''}
                  />
                )}
                <h3 className={styles.Title}>{theme.title[i18n.language]}</h3>

                {theme.description_short && (
                  <div className={styles.Description}>
                    {theme.description_short[i18n.language]}
                  </div>
                )}
              </div>
            </LangLink>
          </div>
        ))}
      </div>
      <Offcanvas
        direction="end"
        className={styles.OffCanvasInfo}
        isOpen={showInfo}
        backdropClassName="offcanvas-backdrop-info"
        toggle={() => {
          setShowInfo(!showInfo)
        }}
      >
        <OffcanvasBody
          style={{
            backgroundColor: 'var(--tertiary-100)',
          }}
        >
          <div className="text-md text-100-primary">{t('informazioni')}</div>
          <div
            className="text-sm mt-2"
            dangerouslySetInnerHTML={{
              __html: textStories.text[i18n.language],
            }}
          ></div>
        </OffcanvasBody>
      </Offcanvas>
    </Layout>
  )
}
