import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { CalendarDocument, PaginatedDJResponse } from '../types'
import { serializeQueryParams, getNextPageParam } from './utils'

export function useTodayCalenderDoc() {
  return useQuery(
    ['todayCalendar'],
    async ({ signal }) =>
      (await axios.get<CalendarDocument>(`/api/calendar/today/`, { signal }))
        .data
  ).data!
}

export async function getCalendarDocs(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get<PaginatedDJResponse<CalendarDocument>>(`/api/calendar/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data
}

export async function getThreeMonthsCalendarDocs(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get<CalendarDocument[]>(
      `/api/calendar-three-months/`,
      {
        signal,
        params: serializeQueryParams(params),
      }
    )
  ).data
}

export function useThreeMonthsCalendarDocs(params: Record<string, any> = {}) {
  return useQuery(
    ['threeMonthsCalendarDocs', params],
    ({ signal }) => getThreeMonthsCalendarDocs(params, signal),
    {
      keepPreviousData: true,
    }
  )
}

export function useInfiniteCalendarDocs(params: Record<string, any> = {}) {
  return useInfiniteQuery(
    ['infiniteCalendarDocs', params],
    ({ signal, pageParam }) =>
      getCalendarDocs(
        {
          ...params,
          ...pageParam,
        },
        signal
      ),
    {
      keepPreviousData: true,
      getNextPageParam,
    }
  )
}

export async function getCalendar(
  idOrSlug: string | number,
  signal?: AbortSignal
) {
  return (await axios.get(`/api/calendar/${idOrSlug}/`, { signal }))
    .data as CalendarDocument
}

export function useCalendar(idOrSlug: string | number) {
  return useQuery(['calendar', idOrSlug], ({ signal }) =>
    getCalendar(idOrSlug, signal)
  ).data!
}
