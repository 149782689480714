import React from 'react'
import ReactDOM from 'react-dom/client'
import './fonts.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider,
  hydrate,
} from '@tanstack/react-query'
import { createI18n } from './i18n'
import { I18nextProvider } from 'react-i18next'
import ErrorBoundary from './components/ErrorBoundary'
import { HelmetProvider } from 'react-helmet-async'
import { RequestContext } from './RequestContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      networkMode: 'always',
      refetchOnMount: false,
      staleTime: Infinity,
      retry: false,
      suspense: true,
      structuralSharing: false,
    },
  },
})

const i18n = createI18n(window.location.pathname)

hydrate(queryClient, (window as any).__INITIAL_DATA__)
delete (window as any).__INITIAL_DATA__

const ClientApp = (
  <React.StrictMode>
    <RequestContext.Provider
      value={{
        host: window.location.host,
        protocol: window.location.protocol.replace(':', ''),
      }}
    >
      <HelmetProvider>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </QueryClientProvider>
          </I18nextProvider>
        </BrowserRouter>
      </HelmetProvider>
    </RequestContext.Provider>
  </React.StrictMode>
)

ReactDOM.hydrateRoot(document.getElementById('root') as HTMLElement, ClientApp)

if (import.meta.env.VITE_SITE_VERSION) {
  console.log(
    `%cHM100 %c${import.meta.env.VITE_SITE_VERSION}`,
    'color:#fff055; background: #7575ff;',
    'color:white;font-weight: bold;background: #7575ff;'
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
