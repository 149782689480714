import { AxiosError } from 'axios'
import { Component, ErrorInfo, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { useTodayCalenderDoc } from '../../hooks/calendar'
import styles from './Home.module.css'
import 'dayjs/locale/it'
import hm100 from '../../assets/hm100.svg'
import NavLangLink from '../../components/NavLangLink'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import placeholder from './assets/placeholder.png'
import about from '../../assets/images/about-page.png'
import { Helmet } from 'react-helmet-async'
import Footer from '../../components/Footer'
import FullPreviewClip from '../../components/FullPreviewClip'
import LangLink from '../../components/LangLink'
import { smartSlug } from '../../utils/slug'
import almanacImg from '../../assets/images/almanacco.png'
import storieDelFormatoImg from '../../assets/images/storie_del_formato_ridotto.jpg'
import aboutImg from '../../assets/images/about.jpeg'
import shapeSquares from '../../assets/shape_squares.svg'
import { useContent } from '../../hooks/contents'
import { useThemes } from '../../hooks/themes'
import FullSerieHome from '../../components/FullSerieHome'

function PlaceholderDocument() {
  const { t } = useTranslation()
  return (
    <div className="h-100 w-100">
      <div className={styles.VimeoContainer}>
        <img src={placeholder} alt="Home Movies 100" className="img-fluid" />
      </div>
      <div>
        <div className={styles.BgClip}>
          <div className={`${styles.BlockInfoUp} row`}>
            <div className="col-md-4">
              <h2 className={styles.TitleUp}>Home Movies 100</h2>
            </div>
            <div className="col-md-3 pt-2"></div>
            <div className="col-md-5 pt-2">
              <div
                className={styles.DescriptionUp}
                dangerouslySetInnerHTML={{
                  __html: t('text_home_movies_100_2'),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container-xl py-5 px-md-5 align-items-center">
          <div className="row">
            <div className="col-md-12 p-5 font-tit h-100">
              <h3 className="mb-5">{t('almanac_page_title')}</h3>
              <p className="font-content">{t('text_almanac')}</p>
              <div className="text-end">
                <NavLangLink className="text-100-primary" end to="/almanac">
                  {t('go_to_almanac')}
                </NavLangLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.homeFooter} bg-100-primary text-white p-4 d-flex justify-content-center`}
      >
        <div className="row">
          <div className="col-lg-8 offset-lg-2 d-flex align-items-center">
            <img
              src={hm100}
              alt="Home movies 100"
              style={{ height: 60 }}
              className="me-3"
            />
            <div className="text-center">
              <div dangerouslySetInnerHTML={{ __html: t('text_footer') }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TodayDocument() {
  const calendar = useTodayCalenderDoc()
  const { t, i18n } = useTranslation()
  const [hoveredBlock, setHoveredBlock] = useState<string | null>(null)
  const homeAlmanacco = useContent('testo-home-almanacco')
  const homeStorie = useContent('testo-home-storie')
  const homeTeameContatti = useContent('testo-home-team-e-contatti')
  const homeText = useContent('testo-home')

  const themes = useThemes()

  const themeInEvidence = themes.filter((theme) => theme.in_evidence)[0] || null

  const [showElement, setShowElement] = useState<
    'almanac' | 'small_format_stories'
  >('almanac')

  // change every 10 seconds the block to show in the home page (almanac, small_format_stories)

  useEffect(() => {
    const interval = setInterval(() => {
      setShowElement((prev) => {
        if (prev === 'almanac') {
          return 'small_format_stories'
        }
        if (prev === 'small_format_stories') {
          return 'almanac'
        }
        return 'almanac'
      })
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Helmet>
        <title>
          HM 100 - HomeMovies100 è un progetto di Fondazione Home Movies
        </title>
        <meta
          name="description"
          content="Home Movies100 è un progetto di Fondazione Home Movies - Archivio Nazionale del Film di Famiglia ETS. Partner: Kiné società cooperativa. Con il contributo di MiC - Direzione Generale Cinema e Audiovisivo e di Regione Emilia Romagna - Emilia Romagna Film Commission. Con il sostegno di Coopfond, fondo mutualistico di Legacoop."
        />
        <meta
          property="og:title"
          content={`HM 100 - HomeMovies100 è un progetto di Fondazione Home Movies`}
        />
        <meta
          property="og:description"
          content={`Home Movies100 è un progetto di Fondazione Home Movies - Archivio Nazionale del Film di Famiglia ETS. Partner: Kiné società cooperativa. Con il contributo di MiC - Direzione Generale Cinema e Audiovisivo e di Regione Emilia Romagna - Emilia Romagna Film Commission. Con il sostegno di Coopfond, fondo mutualistico di Legacoop.`}
        />
        <meta
          property="og:image"
          content={calendar.document.image_preview || ''}
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`HM 100 - HomeMovies100 è un progetto di Fondazione Home Movies`}
        />
        <meta
          name="twitter:description"
          content={`Home Movies100 è un progetto di Fondazione Home Movies - Archivio Nazionale del Film di Famiglia ETS. Partner: Kiné società cooperativa. Con il contributo di MiC - Direzione Generale Cinema e Audiovisivo e di Regione Emilia Romagna - Emilia Romagna Film Commission. Con il sostegno di Coopfond, fondo mutualistico di Legacoop.`}
        />
        <meta
          name="twitter:image"
          content={calendar.document.image_preview || ''}
        />
      </Helmet>
      <div className={classNames('h-100 bg-background-default')}>
        <div
          style={{
            opacity: showElement === 'almanac' ? 1 : 0,
            transition: 'all 0.3s ease-in-out',
            height: showElement === 'almanac' ? '100%' : 0,
            zIndex: showElement === 'almanac' ? 1 : 0,
            pointerEvents: showElement === 'almanac' ? 'auto' : 'none',
          }}>
          <LangLink
            className="no-link"
            to={`/almanac/${smartSlug(
              calendar.id,
              calendar.document.title[i18n.language]
            )}?from_home=1`}
          >
            <FullPreviewClip calendar={calendar} />
          </LangLink>
        </div>
        <div 
          style={{
            opacity: showElement === 'small_format_stories' ? 1 : 0,
            transition: 'all 0.3s ease-in-out',
            height: showElement === 'small_format_stories' ? '100%' : 0,
            zIndex: showElement === 'small_format_stories' ? 1 : 0,
            // avoid click on the block
            pointerEvents: showElement === 'small_format_stories' ? 'auto' : 'none',
          }}>
          <LangLink
            className="no-link"
            to={`/stories/${smartSlug(
              themeInEvidence?.id,
              themeInEvidence?.title[i18n.language] || ''
            )}?from_home=1`}
          >
            <FullSerieHome
              tema={themeInEvidence}
              // calendar={calendar}
            />
          </LangLink>
        </div>
        <div className="mt-3 mb-2">
          <div className={styles.ContentPage}>
            <div className="row pt-2">
              <div
                className="col-md-6"
                style={{
                  backgroundImage: `url("${about}")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                }}
              >
                <div className={styles.ShapeYellow}></div>
                <div className={styles.ShapeBlockBlockHome}>
                  <img src={shapeSquares} alt="almanac" />
                </div>
              </div>
              <div className="col-md-6 ps-4 pt-2">
                <div className={styles.TitleBlockHome}>
                  {homeText.title[i18n.language]}
                </div>
                <div
                  className={`mt-4 ${styles.TextBlock}`}
                  dangerouslySetInnerHTML={{
                    __html: homeText.text[i18n.language],
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Blocks}>
          <div className="d-flex border-bottom flex-column flex-md-row">
            <div
              className={styles.ContainerBlock}
              onMouseEnter={() => {
                setHoveredBlock('almanac')
              }}
              onMouseLeave={() => {
                setHoveredBlock(null)
              }}
            >
              <div
                className={styles.InnerBlock}
                style={{
                  backgroundImage:
                    hoveredBlock === 'almanac'
                      ? `url('${almanacImg}')`
                      : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  // transition: 'all 0.3s ease-in-out',
                }}
              >
                {hoveredBlock === 'almanac' && (
                  <div className={styles.ShapeYellow}></div>
                )}
                {hoveredBlock === 'almanac' && (
                  <div className={styles.ShapeBlock}>
                    <img src={shapeSquares} alt="almanac" />
                  </div>
                )}
                <LangLink to="/almanac" className="no-link">
                  <div className={styles.BlockContent}>
                    <div className={styles.TitleBlock}>
                      {homeAlmanacco.title[i18n.language]}
                    </div>
                    <div
                      style={{
                        backgroundImage: `url('${almanacImg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        height: 250,
                      }}
                      className="d-block position-relative w-100 d-md-none mt-3 mb-3"
                    >
                      <div className={styles.ShapeYellow}></div>
                      <div className={styles.ShapeBlock}>
                        <img src={shapeSquares} alt="almanac" />
                      </div>
                    </div>
                    <div
                      className={styles.TextBlock}
                      dangerouslySetInnerHTML={{
                        __html: homeAlmanacco.text[i18n.language],
                      }}
                    ></div>
                  </div>
                </LangLink>
              </div>
            </div>
            <div
              className={styles.ContainerBlock}
              onMouseEnter={() => {
                setHoveredBlock('small_format_stories')
              }}
              onMouseLeave={() => {
                setHoveredBlock(null)
              }}
            >
              <div
                style={{
                  backgroundImage:
                    hoveredBlock === 'small_format_stories'
                      ? `url('${storieDelFormatoImg}')`
                      : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className={styles.InnerBlock}
              >
                {hoveredBlock === 'small_format_stories' && (
                  <div className={styles.ShapeYellow}></div>
                )}
                {hoveredBlock === 'small_format_stories' && (
                  <div className={styles.ShapeBlock}>
                    <img src={shapeSquares} alt="archive" />
                  </div>
                )}
                <LangLink to="/stories" className="no-link">
                  <div className={styles.BlockContent}>
                    <div className={styles.TitleBlock}>
                      {homeStorie.title[i18n.language]}
                    </div>
                    <div
                      style={{
                        backgroundImage: `url('${storieDelFormatoImg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        height: 250,
                      }}
                      className="d-block position-relative w-100 d-md-none mt-3 mb-3"
                    >
                      <div className={styles.ShapeYellow}></div>
                      <div className={styles.ShapeBlock}>
                        <img src={shapeSquares} alt="almanac" />
                      </div>
                    </div>
                    <div
                      className={styles.TextBlock}
                      dangerouslySetInnerHTML={{
                        __html: homeStorie.text[i18n.language],
                      }}
                    ></div>
                  </div>
                </LangLink>
              </div>
            </div>
            <div
              className={styles.ContainerBlock}
              onMouseEnter={() => {
                setHoveredBlock('about')
              }}
              onMouseLeave={() => {
                setHoveredBlock(null)
              }}
            >
              <div
                className={styles.InnerBlock}
                style={{
                  backgroundImage:
                    hoveredBlock === 'about' ? `url('${aboutImg}')` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {hoveredBlock === 'about' && (
                  <div className={styles.ShapeYellow}></div>
                )}
                {hoveredBlock === 'about' && (
                  <div className={styles.ShapeBlock}>
                    <img src={shapeSquares} alt="calendar" />
                  </div>
                )}
                <LangLink to="/about" className="no-link">
                  <div className={styles.BlockContent}>
                    <div className={styles.TitleBlock}>
                      {homeTeameContatti.title[i18n.language]}
                    </div>
                    <div
                      style={{
                        backgroundImage: `url('${aboutImg}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        height: 250,
                      }}
                      className="d-block position-relative w-100 d-md-none mt-3 mb-3"
                    >
                      <div className={styles.ShapeYellow}></div>
                      <div className={styles.ShapeBlock}>
                        <img src={shapeSquares} alt="almanac" />
                      </div>
                    </div>
                    <div
                      className={styles.TextBlock}
                      dangerouslySetInnerHTML={{
                        __html: homeTeameContatti.text[i18n.language],
                      }}
                    ></div>
                  </div>
                </LangLink>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

class TodayDocumentWithMiss extends Component {
  state: { error: AxiosError | null } = {
    error: null,
  }
  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: AxiosError, errorInfo: ErrorInfo): void {
    if (error.response?.status !== 404) {
      throw error
    }
  }

  render() {
    if (this.state.error) {
      return <PlaceholderDocument />
    }
    return <TodayDocument />
  }
}

export default function Home() {
  return (
    <Layout>
      <TodayDocumentWithMiss />
    </Layout>
  )
}
