export const DOCUMENT_TYPES = [
  ['catalogue', 'Catalogo'],
  ['book', 'Libro'],
  ['letter', 'Lettera'],
  ['poster', 'Locandina'],
  ['card', 'Cartolina'],
  ['notebook', 'Quaderno'],
  ['advertising', 'Pubblicità'],
  ['magazine', 'Rivista'],
  ['manual', 'Manuale'],
  ['photo', 'Foto'],
  ['film', 'Film'],
  ['news', 'Notizia'],
  ['document', 'Documento'],
  ['article', 'Articolo'],
  ['program', 'Programma'],
  ['video_interview', 'Video intervista'],
  ['clip', 'Clip']
]



export const DOCUMENT_TYPES_LABELS = Object.fromEntries(
  DOCUMENT_TYPES
) as Record<string, string>
