import useMeasure from 'react-use-measure'
import { ScaleLinear, scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'
import { memo, useMemo, useRef, useState } from 'react'
import { FacetRecord } from '../../hooks/docs'
import Draggable from 'react-draggable'
import styles from './ArchiveTimeBrush.module.css'
import classNames from 'classnames'

const PADDING_X = 10
const PADDING_Y = 10
const FOOTER_BOTTOM_PADDING = 20
const HANDLE_RADIUS = 7
const HANDLE_RADIUS_BIG =
  HANDLE_RADIUS + parseInt(String((HANDLE_RADIUS / 4) * 3))

interface CircleDragoProps {
  year: number
  yearWidth: number
  height: number
  scaleYears: ScaleLinear<number, number, any>
  onYearChange(year: number): void
  onDragYearChange(year: number | null): void
  limitLeftYear?: number
  limitRightYear?: number
}

function CircleDrag({
  year,
  yearWidth,
  height,
  scaleYears,
  onYearChange,
  limitLeftYear,
  limitRightYear,
  onDragYearChange,
}: CircleDragoProps) {
  const nodeRef = useRef(null)
  const position = useMemo(
    () => ({
      y: 0,
      x: scaleYears(year) - PADDING_X,
    }),
    [scaleYears, year]
  )

  const domain = scaleYears.domain()
  const left = 0
  const right = scaleYears(domain[1]) - PADDING_X

  return (
    <Draggable
      axis="x"
      nodeRef={nodeRef}
      position={position}
      grid={[yearWidth, yearWidth]}
      bounds={{
        left,
        right,
      }}
      scale={1}
      onDrag={(_, data) => {
        let year = Math.round(scaleYears.invert(data.x + PADDING_X))
        if (limitRightYear && year > limitRightYear - 1) {
          return false
        }
        if (limitLeftYear && year < limitLeftYear + 1) {
          return false
        }
        onDragYearChange(parseInt(String(year)))
      }}
      onStop={(_, data) => {
        const year = Math.round(scaleYears.invert(data.lastX + PADDING_X))
        onYearChange(year)
        onDragYearChange(null)
      }}
    >
      <g ref={nodeRef}>
        <circle
          r={HANDLE_RADIUS}
          cx={PADDING_X}
          cy={height - PADDING_Y}
          className={styles.CircleDrag}
        />
        <circle
          r={HANDLE_RADIUS_BIG}
          cx={PADDING_X}
          cy={height - PADDING_Y}
          className={styles.CircleDragBig}
        />
      </g>
    </Draggable>
  )
}

interface BarsProps {
  years: string[]
  scaleYears: ScaleLinear<number, number, any>
  height: number
  dataYear: FacetRecord
  fromYear: number
  toYear: number
}

const Bars = memo(
  ({ years, scaleYears, height, dataYear, fromYear, toYear }: BarsProps) => {
    const scaleBars = useMemo(() => {
      const counts = years.map((y) => dataYear[y])
      const maxCount = Math.max(...counts)
      return scaleLinear()
        .domain([0, maxCount])
        .range([PADDING_Y, height - PADDING_Y])
    }, [dataYear, height, years])

    return (
      <g>
        {years.map((year) => {
          const isSelected = +year >= fromYear && +year <= toYear - 1
          return (
            <rect
              key={year}
              x={scaleYears(+year)}
              y={height - scaleBars(dataYear[year])}
              height={scaleBars(dataYear[year]) - PADDING_Y}
              width={scaleYears(+year + 1) - scaleYears(+year)}
              className={classNames(styles.Bar, {
                [styles.BarSelected]: isSelected,
              })}
              stroke="white"
            />
          )
        })}
      </g>
    )
  }
)

interface YearsChartProps extends ArchiveTimeBrushProps {
  height: number
  width: number
}

function YearsChart({
  dataYear,
  height: fullHeight,
  width,
  fromYear: fromYearRaw,
  toYear: toYearRaw,
  onYearsChange,
}: YearsChartProps) {
  const height = fullHeight - FOOTER_BOTTOM_PADDING

  const years = useMemo(
    () => Object.keys(dataYear).filter((y) => +y > 0),
    [dataYear]
  )

  const scaleYears = useMemo(() => {
    const extentData = extent(years)
    return scaleLinear()
      .domain([+extentData[0]!, +extentData[1]! + 1])
      .range([PADDING_X, width - PADDING_X])
  }, [width, years])

  const domain = scaleYears.domain()

  const fromYear = fromYearRaw ?? domain[0]
  const toYear = toYearRaw ? toYearRaw + 1 : domain[1]

  const yearWidth = useMemo(() => scaleYears(2) - scaleYears(1), [scaleYears])

  const [fromDragYear, setFromDragYear] = useState<number | null>(null)
  const [toDragYear, setToDragYear] = useState<number | null>(null)

  return (
    <svg width={width} height={fullHeight}>
      <Bars
        height={height}
        dataYear={dataYear}
        fromYear={fromYear}
        scaleYears={scaleYears}
        toYear={toYear}
        years={years}
      />
      <g>
        <line
          x1={HANDLE_RADIUS}
          x2={width - HANDLE_RADIUS}
          y1={height - PADDING_Y}
          y2={height - PADDING_Y}
          className={styles.LineDragControls}
        />
      </g>
      <CircleDrag
        yearWidth={yearWidth}
        onDragYearChange={setFromDragYear}
        height={height}
        year={fromYear}
        limitRightYear={toYear}
        onYearChange={(year) => {
          onYearsChange({
            fromYear: year,
            toYear: toYear - 1,
          })
        }}
        scaleYears={scaleYears}
      />
      <CircleDrag
        yearWidth={yearWidth}
        onDragYearChange={setToDragYear}
        height={height}
        year={toYear}
        limitLeftYear={fromYear}
        onYearChange={(year) => {
          onYearsChange({
            fromYear,
            toYear: year - 1,
          })
        }}
        scaleYears={scaleYears}
      />
      <g>
        <text x={0} y={fullHeight - 1} className={styles.SelectedYears}>
          {fromDragYear ?? fromYear} - {(toDragYear ?? toYear) - 1}
        </text>
      </g>
    </svg>
  )
}

interface ArchiveTimeBrushProps {
  dataYear: FacetRecord
  fromYear: number | null
  toYear: number | null
  onYearsChange(years: { fromYear: number; toYear: number }): void
}

export default function ArchiveTimeBrush(props: ArchiveTimeBrushProps) {
  const [ref, bounds] = useMeasure()

  return (
    <div
      className={`${styles.ContainerBrush} pr-md-4 pr-0 my-3  w-100`}
      ref={ref}
    >
      {bounds.width > 0 && (
        <YearsChart width={bounds.width} height={bounds.height} {...props} />
      )}
    </div>
  )
}
