import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
  Location,
} from 'react-router-dom'
import NavigationWrapper from './components/NavigationWrapper'
import NotFound from './components/NotFound'
import { DEFAULT_LANG, getLangFromParam, LANGS } from './i18n'
import About from './pages/About'
// import Archive from './pages/Archive'
import ClipOfTheDay from './pages/ClipOfTheDay'
import DocDetail from './pages/DocDetail'
import Home from './pages/Home'
// import Theme from './pages/Theme'
// import Themes from './pages/Themes'
// import Timeline from './pages/Timeline'
import Almanac from './pages/Almanac'
import AlmanacDetail from './pages/AlmanacDetail'
import imagePlace from './assets/hm100-place.png'
import Analytics from './components/Analytics'
import CookieConsent from './pages/CookieConsent'
import Timeline from './pages/Timeline'
import Archive from './pages/Archive'
import Calendar from './pages/Calendar'
import Themes from './pages/Themes'
import Theme from './pages/Theme'

function AvailablesLang() {
  const { lang } = useParams()
  if (LANGS.includes(lang!)) {
    return <Outlet />
  }
  return <NotFound />
}

// NOTE: This sync lang when changed from push state navigation
// (user press back, forward history)
// This is only need client side
function SyncLang() {
  const { i18n } = useTranslation()
  const params = useParams()
  const lang = params.lang ?? DEFAULT_LANG

  useEffect(() => {
    const memoryLang = i18n.language
    if (memoryLang !== lang) {
      const nextLang = getLangFromParam(lang)
      if (nextLang !== memoryLang) {
        i18n.changeLanguage(nextLang)
      }
    }
  }, [lang, i18n])

  // Seo Language
  const seoLang = getLangFromParam(lang)

  return (
    <Helmet>
      <html lang={seoLang} />
    </Helmet>
  )
}

const ARCHIVE_DOC_TYPES = [
  'catalogue',
  'book',
  'letter',
  'poster',
  'card',
  'notebook',
  'advertising',
  'magazine',
  'manual',
  'photo',
  'news',
  'document',
  'article',
  'program',
  'film',
  'video_interview'
]

function App() {
  const location = useLocation()
  // const prevLocation = useRef<Location | null>(null)

  // useEffect(() => {
  //   prevLocation.current = location
  // }, [location])

  // // NOTE: Avoid "modal" model when no prev location
  // // when user hit refresh page on modal should see non-modal version
  // let backgroundLocation: Location | undefined
  // if (
  //   (location as any).state?.backgroundLocation &&
  //   prevLocation.current !== null
  // ) {
  //   backgroundLocation = (location as any).state.backgroundLocation
  // }

  return (
    <>
      <Helmet>
        <title>
          HM 100 - HomeMovies100 è un progetto di Fondazione Home Movies
        </title>
        <meta
          name="description"
          content="Home Movies100 è un progetto di Fondazione Home Movies - Archivio Nazionale del Film di Famiglia ETS. Partner: Kiné società cooperativa. Con il contributo di MiC - Direzione Generale Cinema e Audiovisivo e di Regione Emilia Romagna - Emilia Romagna Film Commission. Con il sostegno di Coopfond, fondo mutualistico di Legacoop."
        />
        <meta
          property="og:title"
          content={`HM 100 - HomeMovies100 è un progetto di Fondazione Home Movies`}
        />
        <meta
          property="og:description"
          content={`Home Movies100 è un progetto di Fondazione Home Movies - Archivio Nazionale del Film di Famiglia ETS. Partner: Kiné società cooperativa. Con il contributo di MiC - Direzione Generale Cinema e Audiovisivo e di Regione Emilia Romagna - Emilia Romagna Film Commission. Con il sostegno di Coopfond, fondo mutualistico di Legacoop.`}
        />
        <meta property="og:image" content={`${imagePlace}`} />
      </Helmet>
      <Routes location={location}>
        <Route path={':lang/*'} element={<SyncLang />} />
        <Route path="*" element={<SyncLang />} />
      </Routes>
      <Routes location={location}>
        <Route path="clip-of-the-day" element={<ClipOfTheDay />} />
        <Route element={<NavigationWrapper />}>
          <Route
            index
            element={
              <NavigationWrapper isTransparent className="bg-black min-h-100">
                <Home />
              </NavigationWrapper>
            }
          />
          <Route path={':lang/*'} element={<AvailablesLang />}>
            <Route
              index
              element={
                <NavigationWrapper isTransparent className="bg-black min-h-100">
                  <Home />
                </NavigationWrapper>
              }
            />
            {/* <Route
              index
              element={
                <Archive
                  key="archive"
                  titleTransKey={'archive_page_title'}
                  documentTypes={ARCHIVE_DOC_TYPES}
                />
              }
            /> */}
            <Route path="about" element={<About />} />
            <Route path="stories" element={<Themes />} />
            <Route path="stories/:slug" element={<Theme />} />
            <Route path="almanac" element={<Almanac />} />
            <Route path="timeline" element={<Timeline />} />
            <Route path="cookie-consent" element={<CookieConsent />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="almanac/:slug" element={<AlmanacDetail />} />
            <Route
              path="archive"
              element={
                <Archive
                  key="archive"
                  titleTransKey={'archive_page_title'}
                  documentTypes={ARCHIVE_DOC_TYPES}
                />
              }
            />
            {/* <Route
              path="people"
              element={
                <NavigationWrapper className="bg-black min-h-100">
                  <Archive
                    key="people"
                    titleTransKey={'people_page_title'}
                    documentTypes={PEOPLE_DOC_TYPES}
                  />
                </NavigationWrapper>
              }
            /> */}
            <Route path="document/:slug" element={<DocDetail />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
      {/* NOTE: Modal Document! */}
      {/* {backgroundLocation && (
        <Routes>
          <Route path={':lang/*'} element={<AvailablesLang />}>
            <Route path="document/:slug" element={<DocDetail isModal />} />
          </Route>
        </Routes>
      )} */}
      <Analytics />
    </>
  )
}

export default App
