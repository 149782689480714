import { LANGS } from '../../i18n'
import ChangeLangLink from '../ChangeLangLink'
import styles from './TopBar.module.css'
import burger from '../../assets/burger.svg'
import close from '../../assets/close.svg'
import shape from '../../assets/shape_squares.svg'
import placeHolder from '../../assets/hm100-place.png'
import rightCaret from '../../assets/right.svg'
import almanacImg from '../../assets/images/almanacco.png'
import storieDelFormatoImg from '../../assets/images/storie_del_formato_ridotto.jpg'
import aboutImg from '../../assets/images/about.jpeg'
import { useState } from 'react'
import LangLink from '../LangLink'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

interface TopbarProps {
  title?: string
  noTopbar?: boolean
  linkStories?: boolean
}

export default function Topbar({
  title,
  noTopbar = false,
  linkStories = false,
}: TopbarProps) {
  const [menu, setMenu] = useState(false)
  const [hover, setHover] = useState('')
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  return (
    <>
      <div
        className={classNames(styles.Topbar, {
          'd-flex d-md-none': noTopbar,
        })}
      >
        <div className="d-flex align-items-center">
          <LangLink className="no-link" to={'/'}>
            <div className={styles.TitleHome}>Home Movies 1OO</div>
          </LangLink>
          {title && (
            <div className="d-none d-md-block text-lg text-capitalize ms-3">
              <img
                style={{
                  verticalAlign: 'unset',
                }}
                src={rightCaret}
              />
            </div>
          )}
          {title &&
            (linkStories ? (
              <LangLink className="no-link" to={'/stories'}>
                <div className="d-none d-md-block text-lg text-100-primary ms-3">
                  {title}
                </div>
              </LangLink>
            ) : (
              <div className="d-none d-md-block text-lg text-100-primary text-capitalize ms-3">
                {title}
              </div>
            ))}
        </div>
        <div className="d-flex">
          {LANGS.map((lang) => (
            <ChangeLangLink
              key={lang}
              className="d-none d-md-block text-md text-uppercase nav-link ms-3"
              lang={lang}
              activeLangClassName={styles.activeLang}
            >
              {lang}
            </ChangeLangLink>
          ))}
          <div
            className="pointer"
            onClick={() => {
              setMenu(!menu)
            }}
          >
            {menu ? (
              <img src={close} alt="close" className="pointer" />
            ) : (
              <img src={burger} alt="burger" className="ms-5" />
            )}
          </div>
        </div>
      </div>
      <div
        className={styles.Menu}
        style={{
          opacity: menu ? 1 : 0,
          pointerEvents: menu ? 'all' : 'none',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <div
          className={styles.CloseMenu}
          onClick={() => {
            setMenu(!menu)
          }}
        >
          <img src={close} alt="close" className="pointer" />
        </div>
        <div className={`row ${styles.ItemsContainer}`}>
          <div
            className={`${styles.MenuItemFirst}`}
            onClick={() => {
              navigate('/' + i18n.language + '/almanac')
              setMenu(!menu)
            }}
            style={{
              backgroundImage:
                hover === 'almanac' ? `url(${almanacImg})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            onMouseEnter={() => setHover('almanac')}
            onMouseLeave={() => setHover('')}
          >
            <div className={styles.FirstShape}>
              <img src={shape} alt="shape" />
            </div>
            <div className="h-100 d-flex align-items-center">
              {t('almanac')}
            </div>
          </div>

          <div
            className={`${styles.MenuItemSecond}`}
            onClick={() => {
              navigate('/' + i18n.language + '/stories')
              setMenu(!menu)
            }}
            style={{
              backgroundImage:
                hover === 'archive' ? `url(${storieDelFormatoImg})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            onMouseEnter={() => setHover('archive')}
            onMouseLeave={() => setHover('')}
          >
            <div className={styles.SecondShape}>
              <img src={shape} alt="shape" />
            </div>
            <div className="h-100 d-flex align-items-center">
              {t('small_format_stories')}
            </div>
          </div>
          <div
            onClick={() => {
              navigate('/' + i18n.language + '/about')
              setMenu(!menu)
            }}
            onMouseEnter={() => setHover('about')}
            onMouseLeave={() => setHover('')}
            style={{
              backgroundImage: hover === 'about' ? `url(${aboutImg})` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            className={`${styles.MenuItemThird}`}
          >
            <div className={styles.ThirdShape}>
              <img src={shape} alt="shape" />
            </div>
            <div className="h-100 d-flex align-items-center">
              {t('about_page_title')}
            </div>
          </div>
          <div className="d-flex d-md-none mt-3">
            {LANGS.map((lang) => (
              <ChangeLangLink
                key={lang}
                className={classNames('text-md text-uppercase nav-link', {
                  'ms-3': lang !== 'en',
                })}
                lang={lang}
                activeLangClassName={styles.activeLang}
              >
                {lang}
              </ChangeLangLink>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
