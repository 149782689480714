import { useEffect, useRef } from 'react'

export type KeyDownHandler = (e: KeyboardEvent) => void

export function useKeyDown(handler: KeyDownHandler) {
  const keyDownHandlerRef = useRef<KeyDownHandler>(handler)
  useEffect(() => {
    keyDownHandlerRef.current = handler
  })
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      const cb = keyDownHandlerRef.current
      if (typeof cb === 'function') {
        cb(e)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
}
