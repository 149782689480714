import classNames from 'classnames'
import dayjs from 'dayjs'
import { useContext, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import FullClipOfTheDay from '../../components/FullClipOfTheDay'
import Layout from '../../components/Layout'
import { useCalendar, useInfiniteCalendarDocs } from '../../hooks/calendar'
import { RequestContext } from '../../RequestContext'
import { parseSmartSlug } from '../../utils/slug'
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg'
import styles from './AlmanacDetail.module.css'

export default function AlmanacDetail() {
  const { slug } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const calendar = useCalendar(parseSmartSlug(slug!))
  const reqInfo = useContext(RequestContext)
  const location = useLocation()
  const shareUrl = `${reqInfo.protocol}://${reqInfo.host}${location.pathname}`
  const { i18n, t } = useTranslation()
  const fromHome = Number(searchParams.get('from_home')) === 1

  const {
    data: infiniteCalendarDos,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteCalendarDocs()

  const cyclesDocIds = useMemo(() => {
    return infiniteCalendarDos!.pages.reduce((all, docs) => {
      docs.results.forEach((doc) => {
        all.push(doc.id)
      })
      return all
    }, [] as number[])
  }, [infiniteCalendarDos])

  const prevClip = useMemo(() => {
    const index = cyclesDocIds.indexOf(calendar.id)
    if (index === -1) {
      return null
    }
    if (index === 0) {
      return null
    }
    return cyclesDocIds[index - 1]
  }, [calendar.document.id, cyclesDocIds])

  const nextClip = useMemo(() => {
    const index = cyclesDocIds.indexOf(calendar.id)
    if (index === -1) {
      return null
    }
    if (index === cyclesDocIds.length - 1) {
      return null
    }
    return cyclesDocIds[index + 1]
  }, [calendar.document.id, cyclesDocIds])

  return (
    <Layout noTopbar>
      {/* TODO: SKAFFO TEST DYNAMIC SSR */}
      <Helmet>
        <title>{`HM 100 - ${calendar.document.title['it']}`}</title>
        <meta
          name="description"
          content={`${calendar.document.description['it']}`}
        />
        <meta
          property="og:title"
          content={`HM 100 - ${calendar.document.title['it']}`}
        />
        <meta
          property="og:description"
          content={`${calendar.document.description['it']}`}
        />
        <meta
          property="og:image"
          content={`${calendar.document.image_preview}`}
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`HM 100 - ${calendar.document.title['it']}`}
        />
        <meta
          name="twitter:description"
          content={`${calendar.document.description['it']}`}
        />
        <meta
          name="twitter:image"
          content={calendar.document.image_preview || ''}
        />
      </Helmet>
      <div className={classNames('h-100')}>
        <div className={styles.FullClip}>
          <div
            className={prevClip ? styles.PrevClip : styles.PrevClipDisabled}
            onClick={() => {
              if (prevClip) {
                navigate(`/${i18n.language}/almanac/${prevClip}`)
              }
            }}
          >
            {prevClip && <ArrowLeft />}
          </div>
          <div className={styles.ClipContainer}>
            <FullClipOfTheDay fromHome={fromHome} calendar={calendar} />
            <div className={styles.ContentPage}>
              <div className={styles.Info}>
                <div className={styles.ContainerDateAndTitle}>
                  <div className="text-md text-100-primary">
                    {dayjs(calendar.date)
                      .locale(i18n.language)
                      .format('DD MMMM')}
                    <span className="ms-1">
                      {dayjs(calendar.document.date)
                        .locale(i18n.language)
                        .format('YYYY')}
                    </span>
                  </div>
                  <div className="text-lg text-100-primary">
                    {calendar.document.title[i18n.language]}
                  </div>
                </div>
                <div className={styles.InfoClipUp}>
                  {calendar.document.document_type === 'clip' && (
                    <>
                      <div
                        className={`${styles.ContainerInfoTable} d-flex align-items-center w-100 border-top py-2`}
                      >
                        <div className="text-100-primary min-width-100">
                           {t('author')}
                        </div>
                        <div className="">
                          {calendar.document.data.author
                            ? calendar.document.data.author
                            : ''}{' '}
                        </div>
                      </div>
                      <div
                        className={`${styles.ContainerInfoTable} d-flex align-items-center w-100 border-top py-2`}
                      >
                        <div className="text-100-primary min-width-100">
                           {t('format')}
                        </div>
                        <div className="">{calendar.document.data.format}</div>
                      </div>
                      {calendar.document.data.duration && (
                        <div
                          className={`${styles.ContainerInfoTable} d-flex align-items-center w-100 border-top py-2`}
                        >
                          <div className="text-100-primary min-width-100">
                             {t('duration')}
                          </div>
                          <div className="">
                            {calendar.document.data.duration}
                          </div>
                        </div>
                      )}
                      {calendar.document.data.sound && (
                        <div
                          className={`${styles.ContainerInfoTable} d-flex align-items-center w-100 border-top border-bottom py-2`}
                        >
                          <div className="text-100-primary min-width-100">
                             {t('credits')}
                          </div>
                          <div className="">
                            {calendar.document.data.sound
                              ? calendar.document.data.sound[i18n.language]
                              : ''}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.Description}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: calendar.document.description[i18n.language],
                  }}
                ></div>
                <div className={`mt-4 ${styles.Socials}`}>
                  <div>{t('share')}</div>
                  <FacebookShareButton
                    url={shareUrl}
                    className={'me-3 text-100-primary'}
                  >
                    Facebook
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    className={'me-3 text-100-primary'}
                  >
                    Twitter
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={shareUrl}
                    className={'me-3 text-100-primary'}
                  >
                    Whatsapp
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={shareUrl}
                    className={'me-3 text-100-primary'}
                  >
                    Mail
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
          <div
            className={nextClip ? styles.NextClip : styles.NextClipDisabled}
            onClick={() => {
              if (nextClip) {
                navigate(`/${i18n.language}/almanac/${nextClip}`)
              }
            }}
          >
            {nextClip && <ArrowRight />}
          </div>
        </div>
        {/* <FullClipOfTheDay isDetailAlmanac showBack calendar={calendar} /> */}
      </div>
    </Layout>
  )
}
