import { ReactNode, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Layout from './Layout'
import Spinner from './Spinner'

interface NavigationWrapperProps {
  className?: string
  children?: ReactNode
  isTransparent?: boolean
}

export default function NavigationWrapper({
  className = '',
  children,
  isTransparent = false
}: NavigationWrapperProps) {
  return (
    <Suspense
      fallback={
        <Layout isTransparent={isTransparent} className={className}>
          <div className="d-flex justify-content-center p-4">
            <Spinner />
          </div>
        </Layout>
      }
    >
      {children ? children : <Outlet />}
    </Suspense>
  )
}
