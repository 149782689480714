import { useTranslation } from 'react-i18next'
import styles from './Footer.module.css'
import logoFooter from '../../assets/logo_footer.svg'

export default function Footer() {
  const { t } = useTranslation()
  return (
    <div className={styles.Footer}>
      <div className="d-flex flex-column flex-md-row">
        <div className={styles.Socials}>
          <div>
            <a
              target={'_blank'}
              href="https://www.facebook.com/archiviohomemovies"
            >
              Facebook
            </a>
          </div>
          <div>
            <a target={'_blank'} href="https://www.instagram.com/archiviohomemovies/">Instagram</a>
          </div>
        </div>
        <div
          className={`ps-0 ps-md-5 ms-0 ms-md-5 pt-2 pt-md-4 ${styles.TextFooter}`}
        >
          {t('text_footer')}
        </div>
      </div>
      <div className="pt-4">
        <img src={logoFooter} alt="logo" />
      </div>
    </div>
  )
}
