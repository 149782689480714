import { Doc } from '../../types'

interface DocAudioProps {
  doc: Doc
  isModal?: boolean
}

export default function DocAudio({ doc }: DocAudioProps) {
  return (
    <div className="d-flex align-items-center h-100 justify-content-center">
      <audio controls>
        <source src={doc.file_content!} />
      </audio>
    </div>
  )
}
