import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlayIcon } from '../../assets/play.svg'
import { Doc, Theme } from '../../types'
import styles from './DocVideo.module.css'

interface DocVideoProps {
  doc: Doc | Theme
  isModal?: boolean
}

export default function DocVideo({ doc }: DocVideoProps) {
  const [showPreview, setShowPreview] = useState(true)
  const [paused, setPaused] = useState(true)
  const [ended, setEnded] = useState(false)
  const { i18n } = useTranslation()
  const videoRef = useRef<any>()

  const handlePlayerPause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setPaused(true)
    }
  }

  const handlePlayerPlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setPaused(false)
      setShowPreview(false)
    }
  }

  // const subtitles = useMemo(() => {
  //   if (doc.subtitle_content) {
  //     const sub = doc.subtitle_content.split('&download=')[0]
  //     return sub
  //   }
  //   return ''
  // }, [doc.subtitle_content])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('contextmenu', (e: any) => {
        e.preventDefault()
      })
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('contextmenu', (e: any) => {
          e.preventDefault()
        })
      }
    }
  }, [videoRef])

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div
        className={classNames(`${styles.Preview}`, {
          'd-none': !showPreview,
        })}
        style={{ backgroundImage: `url(${doc.image_medium})` }}
      >
        <div className={`${styles.PlayIcon}`} onClick={handlePlayerPlay}>
          <PlayIcon />
        </div>
      </div>
      <video
        controlsList="nodownload"
        onEnded={() => {
          handlePlayerPause()
          setEnded(true)
        }}
        onClick={() => {
          if (paused) {
            handlePlayerPlay()
          } else {
            handlePlayerPause()
          }
        }}
        ref={videoRef}
        className={classNames('w-100 h-100', {
          'd-none': showPreview,
        })}
        width={'100%'}
        controls={paused === false ? true : false}
      >
        <source src={doc.url_content ?? ''} type="video/mp4" />
        {doc.subtitle_content !== '' && i18n.language === 'en'  && (
        <track
          srcLang="en"
          label="English"
          kind="captions"
          src={doc.subtitle_content ?? ''}
          default
        />
        )}
      </video>
    </div>
  )
}
