import { ReactComponent as ArrowLeft } from '../../assets/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg'
import { ReactNode, Suspense, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DocAudio from '../../components/DocAudio'
import DocDetailInfo from '../../components/DocDetailInfo'
import DocPdf from '../../components/DocPdf'
import DocVideo from '../../components/DocVideo'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import Spinner from '../../components/Spinner'
import ZoomAndPanMedia from '../../components/ZoomAndPanMedia'
import { useDoc } from '../../hooks/docs'
import { useKeyDown } from '../../hooks/key'
import { useLangPathPrefix } from '../../hooks/lang'
import { useBlockBodyScroll } from '../../hooks/screen'
import { parseSmartSlug } from '../../utils/slug'
import closeClip from '../../assets/close-white.svg'
import styles from './DocDetail.module.css'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

function CloseOnEsc({ onClose }: { onClose: () => void }) {
  useKeyDown((e) => {
    if (e.key === 'Escape') {
      onClose()
    }
  })
  return null
}
interface DocDetailProps {
  isModal?: boolean
}

function DocInfo({ isModal }: DocDetailProps) {
  const { slug } = useParams()
  const doc = useDoc(parseSmartSlug(slug!))
  const { i18n } = useTranslation()

  const navigate = useNavigate()
  const onClose = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <div className="h-100 w-100 d-flex bg-black">
      <Helmet>
        <title>{`HM 100 - ${doc.title['it']}`}</title>
        <meta
          name="description"
          content={`${doc.description['it']}`}
        />
        <meta
          property="og:title"
          content={`HM 100 - ${doc.title['it']}`}
        />
        <meta
          property="og:description"
          content={`${doc.description['it']}`}
        />
        <meta
          property="og:image"
          content={`${doc.image_preview}`}
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`HM 100 - ${doc.title['it']}`}
        />
        <meta
          name="twitter:description"
          content={`${doc.description['it']}`}
        />
        <meta
          name="twitter:image"
          content={doc.image_preview || ''}
        />
      </Helmet>
      <div className={styles.DocDetailData}>
        <div
          className={styles.CloseVideo}
          onClick={() => {
            onClose()
          }}
        >
          <img src={closeClip} alt="close" />
        </div>
        <div className="container-fluid h-100 px-0">
          <div
            className={`${styles.RowContainer} row w-100 no-gutters-mobile flex-column flex-lg-row`}
          >
            <div
              className={`${styles.DocMediaContainer} col-lg-7 order-0 order-md-1 order-lg-1`}
            >
              {doc.content_type === 'image' && (
                <ZoomAndPanMedia src={doc.file_content!} isModal={isModal} />
              )}
              {doc.content_type === 'pdf' && (
                <DocPdf doc={doc} isModal={isModal} />
              )}
              {doc.content_type === 'audio' && (
                <DocAudio doc={doc} isModal={isModal} />
              )}
              {doc.content_type === 'video' && (
                <DocVideo doc={doc} isModal={isModal} />
              )}
            </div>

            <div
              className={`${styles.DocDetailInfoContainer} position-relative col-lg-5 d-flex order-1 order-md-0 order-lg-0`}
            >
              <DocDetailInfo doc={doc} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function WrapWithNextPrev({
  children,
  cyclesDocIds,
}: {
  children: ReactNode
  cyclesDocIds: number[]
}) {
  const location = useLocation()
  const { slug } = useParams()
  const backgroundLocation = (location.state! as any).backgroundLocation

  const i = cyclesDocIds.indexOf(Number(parseSmartSlug(slug!)))
  const prevIndex = i === 0 ? cyclesDocIds.length - 1 : i - 1
  const nextIndex = i === cyclesDocIds.length - 1 ? 0 : i + 1

  const prevSlug = cyclesDocIds[prevIndex]
  const nextSlug = cyclesDocIds[nextIndex]

  const langPrefix = useLangPathPrefix()
  const navigate = useNavigate()

  useKeyDown((e) => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault()
      navigate(`${langPrefix}/document/${prevSlug}`, {
        replace: true,
        state: { cyclesDocIds, backgroundLocation },
      })
    } else if (e.key === 'ArrowRight') {
      e.preventDefault()
      navigate(`${langPrefix}/document/${nextSlug}`, {
        replace: true,
        state: { cyclesDocIds, backgroundLocation },
      })
    }
  })

  return (
    <div className="d-flex h-100 w-100">
      <LangLink
        replace
        // className={'btn-circle text-white bg-dark-gray'}
        to={`/document/${prevSlug}`}
        state={{ cyclesDocIds, backgroundLocation }}
      >
        <div className={styles.PrevClip}>
          <ArrowLeft />
        </div>
      </LangLink>
      {children}
      <LangLink
        replace
        // className={'btn-circle text-white bg-dark-gray'}
        to={`/document/${nextSlug}`}
        state={{ cyclesDocIds, backgroundLocation }}
      >
        <div className={styles.NextClip}>
          <ArrowRight />
        </div>
      </LangLink>
    </div>
  )
}

function MaybeWrapWithNextPrev({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const location = useLocation()
  const cyclesDocIds = (location.state as any)?.cyclesDocIds

  if (!cyclesDocIds) {
    return children as JSX.Element
  }

  return (
    <WrapWithNextPrev cyclesDocIds={cyclesDocIds}>{children}</WrapWithNextPrev>
  )
}

export default function DocDetail({ isModal = false }: DocDetailProps) {
  useBlockBodyScroll(isModal)

  if (isModal) {
    return (
      <div className={styles.ModalDoc}>
        <Suspense
          fallback={
            <div className="text-center p-4">
              <Spinner />
            </div>
          }
        >
          <DocInfo isModal={false} />
        </Suspense>
      </div>
    )
  } else {
    return (
      <Layout noTopbar className="h-100 w-100">
        <MaybeWrapWithNextPrev>
          <DocInfo isModal={false} />
        </MaybeWrapWithNextPrev>
      </Layout>
    )
  }
}
