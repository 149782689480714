import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Close } from '../assets/close.svg'
import { Modal, ModalBody } from 'reactstrap'

interface AutoTipModalProps {
  text: string
  type: string
}

export default function AutoTipModal({ text, type }: AutoTipModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const storeKey = 'modalTip:' + type
  const { t } = useTranslation()

  useEffect(() => {
    const showed = sessionStorage.getItem(storeKey)
    if (!showed) {
      setIsOpen(true)
    }
  }, [storeKey])

  return (
    <Modal size='lg' isOpen={isOpen} centered>
      <ModalBody className="auto-tip-modal text-white p-5 bg-100-primary rounded">
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            padding: '1rem',
          }}
        >
          <Close
            style={{
                width: '1.5rem',
                cursor: 'pointer',
            }}
            onClick={() => {
              setIsOpen(false)
              sessionStorage.setItem(storeKey, 'yes')
            }}
          />
        </div>
        <div>
            <h3 className="text-white">{t('informazioni')}</h3>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></div>
      </ModalBody>
    </Modal>
  )
}
