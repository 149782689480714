import { QueryClient, useInfiniteQuery, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import {
  Doc,
  DocDetail,
  DocSuggest,
  DocTimeline,
  PaginatedDJResponse,
} from '../types'
import { serializeQueryParams, getNextPageParam } from './utils'

export type FacetRecord = Record<string, number>

export type AllFacets = Record<string, FacetRecord> & { count: number }

type MakeFacets<T extends readonly string[]> = Record<
  T[number],
  FacetRecord
> & { count: number }

export function getDocsFacets<T extends readonly string[]>(
  facets: T,
  params: Record<string, any>,
  signal?: AbortSignal
): Promise<MakeFacets<T>>

export async function getDocsFacets(
  facets: readonly string[],
  params: Record<string, any>,
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/documents/facets/`, {
      signal,
      params: serializeQueryParams({ ...params, facets }),
    })
  ).data as AllFacets
}

export function useDocsFacets<T extends readonly string[]>(
  facets: T,
  params: Record<string, any> = {}
) {
  return useQuery(
    ['docsFacets', { facets, params }],
    ({ signal }) => getDocsFacets<T>(facets, params, signal),
    {
      keepPreviousData: true,
    }
  ).data!
}

export function prefetchDocsFacets(
  client: QueryClient,
  facets: readonly string[],
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(
    ['docsFacets', { facets, params }],
    ({ signal }) => getDocsFacets(facets, params, signal)
  )
}

export async function getDoc(idOrSlug: string | number, signal?: AbortSignal) {
  return (await axios.get(`/api/documents/${idOrSlug}/`, { signal }))
    .data as DocDetail
}

export function useDoc(idOrSlug: string | number) {
  return useQuery(['doc', idOrSlug], ({ signal }) => getDoc(idOrSlug, signal))
    .data!
}

export function prefetchDoc(client: QueryClient, idOrSlug: string | number) {
  return client.prefetchQuery(['doc', idOrSlug], ({ signal }) =>
    getDoc(idOrSlug, signal)
  )
}

export async function getDocs(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/documents/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as PaginatedDJResponse<Doc>
}

export function useDocs(params: Record<string, any> = {}) {
  return useQuery(['docs', params], ({ signal }) => getDocs(params, signal), {
    keepPreviousData: true,
  })
}

export function prefetchDocs(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(['docs', params], ({ signal }) =>
    getDocs(params, signal)
  )
}

export function useInfiniteDocs(params: Record<string, any> = {}) {
  return useInfiniteQuery(
    ['infiniteDocs', params],
    ({ signal, pageParam }) =>
      getDocs(
        {
          ...params,
          ...pageParam,
        },
        signal
      ),
    {
      keepPreviousData: true,
      getNextPageParam,
    }
  )
}

export function prefetchInfiniteDocs(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchInfiniteQuery(['infiniteDocs', params], ({ signal }) =>
    getDocs(params, signal)
  )
}

export async function getDocsSuggest(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/documents-suggest/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as DocSuggest[]
}

export async function getDocsTimeline(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`/api/documents/timeline/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as DocTimeline[]
}

export function useTimelineDocs(params: Record<string, any> = {}) {
  return useQuery(
    ['timelineDocs', params],
    ({ signal }) => getDocsTimeline(params, signal),
    {
      keepPreviousData: true,
    }
  )
}

export function prefetchTimelineDocs(
  client: QueryClient,
  params: Record<string, any> = {}
) {
  return client.prefetchQuery(['timelineDocs', params], ({ signal }) =>
    getDocsTimeline(params, signal)
  )
}
