import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import DocItem from '../../components/DocItem'
import LangLink from '../../components/LangLink'
import Layout from '../../components/Layout'
import { useTheme } from '../../hooks/themes'
import { Doc } from '../../types'
import { createColGenerator } from '../../utils/col'
import { parseSmartSlug, smartSlug } from '../../utils/slug'
import styles from './Theme.module.css'
import upIcon from '../../assets//up.svg'
import downIcon from '../../assets/down.svg'
import DocVideo from '../../components/DocVideo'

function isDocImageHoriz(doc: Doc) {
  if (!doc.content_metadata.width || !doc.content_metadata.height) {
    return false
  }
  return doc.content_metadata.width! > doc.content_metadata.height!
}

export default function Theme() {
  const { i18n } = useTranslation()
  const { slug } = useParams()
  const theme = useTheme(parseSmartSlug(slug!))
  const location = useLocation()

  const cyclesDocIds = useMemo(() => {
    return theme!.related_documents.reduce((all, theme) => {
      all.push(theme.id)
      return all
    }, [] as number[])
  }, [theme])

  const [open, setOpen] = useState('')

  function toggle(newIndex: string) {
    if (newIndex === open) {
      setOpen('')
    } else {
      setOpen(newIndex)
    }
  }

  const getDesktopCol = createColGenerator(4)
  const getMobileCol = createColGenerator(1)

  const { t } = useTranslation()

  return (
    <Layout linkStories title={t('themes_page_title')}>
      <div className="container-fluid mt-4 px-4 mt-md-5">
        <div className="row">
          <div className="col-md-4">
            <h1 className={styles.Title}>{theme.title[i18n.language]}</h1>
          </div>
          <div className="col-md-8">
            <div className={styles.Video}>
              <DocVideo doc={theme} />
            </div>
            <div className="mt-2">
              <div
                className={'text-md'}
                dangerouslySetInnerHTML={{
                  __html: theme.description[i18n.language],
                }}
              ></div>
            </div>
            {theme?.bibliography[i18n.language] && (
              <>
                <div className="mt-3 w-100">
                  <div
                    className="text-md text-100-primary d-flex justify-content-between"
                    onClick={() => {
                      toggle('biblio')
                    }}
                  >
                    {t('bibliografia')}
                    <div>
                      {open === 'biblio' ? (
                        <img src={upIcon} alt="up" style={{ width: '20px' }} />
                      ) : (
                        <img
                          src={downIcon}
                          alt="down"
                          style={{ width: '20px' }}
                        />
                      )}
                    </div>
                  </div>
                  {open === 'biblio' && theme?.bibliography[i18n.language] && (
                    <div
                      className="text-md mt-2"
                      style={{
                        height: open === 'biblio' ? 'auto' : '0px',
                        transition: 'height 0.5s ease-in-out',
                      }}
                    >
                      {theme.bibliography[i18n.language]}
                    </div>
                  )}
                </div>
                <hr />
              </>
            )}

            {theme?.credits[i18n.language] && (
              <div className="mt-3 w-100">
                <div
                  className="text-md text-100-primary d-flex justify-content-between"
                  onClick={() => {
                    toggle('credits')
                  }}
                >
                  {t('credits')}
                  <div>
                    {open === 'credits' ? (
                      <img src={upIcon} alt="up" style={{ width: '20px' }} />
                    ) : (
                      <img
                        src={downIcon}
                        alt="down"
                        style={{ width: '20px' }}
                      />
                    )}
                  </div>
                </div>
                {open === 'credits' && theme?.bibliography && (
                  <div
                    className="text-md mt-2"
                    style={{
                      height: open === 'credits' ? 'auto' : '0px',
                      transition: 'height 0.5s ease-in-out',
                    }}
                  >
                    {theme.credits[i18n.language]}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.DocumentsCorrelatiContainer}>
        {theme.related_documents.length > 0 && (
          <div
            className="row mt-5 mb-4 ms-0 me-0"
            style={{
              borderTop: '1px solid black',
            }}
          >
            <h3 className={`pt-3 pb-3 ${styles.MaterialiCollegati}`}>
              {t('esplora_i_contenuti')}
            </h3>
            {theme.related_documents.map((doc, i) => {
              const size = isDocImageHoriz(doc) ? 1 : 1
              // Dekstop
              const desktopClass = `col-md-${getDesktopCol(size)}`
              // Mobile
              const mobileClass = `col-${getMobileCol(size)}`
              return (
                <div
                  key={doc.id}
                  className={`${desktopClass} ${mobileClass}`}
                  style={{
                    borderRight: '1px solid #E5E5E5',
                    borderTop: '1px solid #E5E5E5',
                  }}
                >
                  <LangLink
                    className="no-link"
                    state={{ backgroundLocation: location, cyclesDocIds }}
                    to={`/document/${smartSlug(
                      doc.id,
                      doc.title[i18n.language]
                    )}?from_theme=${theme.id}`}
                    key={doc.id}
                  >
                    <DocItem doc={doc} />
                  </LangLink>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Layout>
  )
}
