import { useTranslation } from 'react-i18next'
import Footer from '../../components/Footer'
import FullPreviewClip from '../../components/FullPreviewClip'
import Layout from '../../components/Layout'
import { useContent } from '../../hooks/contents'
import styles from './About.module.css'

export default function About() {
  const { t, i18n } = useTranslation()
  const aboutText = useContent('testo-pagina-team-e-contatti')
  const teamHome = useContent('team-home')
  const teamAlmanacco = useContent('team-almanacco')
  const teamStorie = useContent('team-storie')

  return (
    <Layout title={t('about_page_title')}>
      <FullPreviewClip />
      <div className={styles.ContainerPage}>
        <div className="d-flex text-md w-100 justify-content-end">
          <div
            className={styles.TextAboutBig}
            dangerouslySetInnerHTML={{
              __html: aboutText.text[i18n.language],
            }}
          ></div>
        </div>
      </div>
      <div className={styles.TeamSection}>
        <div className="text-xl ps-3">{t('team')}</div>
        <div className="row ps-2 pe-2 mt-4 border">
          <div className="col-md-4 ps-4 pt-4 pb-4 border-end border-start">
            <div className="text-md text-100-primary">
              {teamHome.title[i18n.language]}
            </div>
            <div className="text-md mt-3 description-team">
              <div
                dangerouslySetInnerHTML={{
                  __html: teamHome.text[i18n.language],
                }}
              ></div>
            </div>
          </div>
          <div className="col-md-4 ps-4 pt-4 pb-4 border-end">
            <div className="text-md text-100-primary">
              {teamAlmanacco.title[i18n.language]}
            </div>
            <div className="text-md mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: teamAlmanacco.text[i18n.language],
                }}
              ></div>
            </div>
          </div>
          <div className="col-md-4 ps-4 pe-4 pt-4 pb-4">
            <div className="text-md text-100-primary">
              {teamStorie.title[i18n.language]}
            </div>
            <div className="text-md mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: teamStorie.text[i18n.language],
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
