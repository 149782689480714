import { AxiosError } from 'axios'
import { Component, ErrorInfo } from 'react'
import { useTodayCalenderDoc } from '../hooks/calendar'

function TodayDocument() {
  const calendar = useTodayCalenderDoc()

  return (
    <div style={{ height: '100vh', width: '100vw', margin: 0, padding: 0 }} className="d-flex align-items-center bg-black" >
      <video
        title="Video Preview"
        loop
        width={'100%'}
        autoPlay
        controls
        muted
        src={
          calendar.document.url_content || ''
        }
        style={{ height: '100%', width: '100%', objectFit: 'contain' }}
      />
    </div>
  )
}

class TodayDocumentWithMiss extends Component {
  state: { error: AxiosError | null } = {
    error: null,
  }
  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: AxiosError, errorInfo: ErrorInfo): void {
    if (error.response?.status !== 404) {
      throw error
    }
  }

  render() {
    if (this.state.error) {
      return <div>No document today....</div>
    }
    return <TodayDocument />
  }
}

export default function ClipOfTheDay() {
  return <TodayDocumentWithMiss />
}
