import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/Layout'
import { useThreeMonthsCalendarDocs } from '../../hooks/calendar'
import leftCaret from '../../assets/left.svg'
import rightCaret from '../../assets/right.svg'
import 'dayjs/locale/it'
import styles from './Calendar.module.css'
import LangLink from '../../components/LangLink'
import { Calendar as ReactCalendar } from 'react-calendar'
import { groupBy } from 'lodash-es'
import { CalendarDocument } from '../../types'
import { smartSlug } from '../../utils/slug'
import AutoTipModal from '../../components/AutoTipModal'
import { useContent } from '../../hooks/contents'
import { Offcanvas, OffcanvasBody } from 'reactstrap'

export default function Calendar() {
  const { i18n, t } = useTranslation()
  const { language } = i18n
  const [showInfo, setShowInfo] = useState(false)

  const refCalendar = useRef<any>(null)

  const [activeClip, setActiveClip] = useState<CalendarDocument | null>(null)

  const { data: clips } = useThreeMonthsCalendarDocs()

  const clipsByDay = useMemo(() => {
    if (!clips) return []
    return groupBy(clips, (clip) => dayjs(clip.date).format('DD/MM/YYYY'))
  }, [clips]) as Record<string, any>

  const [activeMonth, setActiveMonth] = useState<string | null>(null)

  useEffect(() => {
    if (!refCalendar.current) return
    const calendar = refCalendar.current
    console.log('calendar', calendar)
    const activeStartDate = calendar?.activeStartDate
    if (!activeStartDate) return
    setActiveMonth(dayjs(activeStartDate).locale(language).format('MMMM'))
  }, [])

  const textAlmanacco = useContent('testo-pagina-almanacco')

  const startDate = '01-01-2023'
  const endDate = '31-12-2023'

  const defaultActiveDate = useMemo(() => {
    // current month but of the year 2023
    return dayjs().set('year', 2023).toDate()
  }, [])

  // calendar is set to 2023 and is circular

  return (
    <Layout className="min-h-100" title={t('calendar')}>
      <AutoTipModal type="almanacco" text={textAlmanacco.text[i18n.language]} />
      <div className="">
        <div className={styles.TopAlmanac}>
          <div>
            <div className={styles.SwitchVisualization}>
              <LangLink to={'/almanac'} className={'no-link'}>
                <div className={styles.SwitchItem}>{t('list')}</div>
              </LangLink>
              <div className={styles.SwitchItemActive}>{t('calendar')}</div>
              <LangLink to={'/timeline'} className={'no-link'}>
                <div className={styles.SwitchItemThird}>{t('timeline')}</div>
              </LangLink>
            </div>
          </div>
          <div>
            <div className={styles.SwitchMonth}>
              <div className="me-5">
                <img
                  src={leftCaret}
                  alt="left"
                  className="pointer"
                  onClick={() => {
                    // if current month is january, go to december of the same year
                    if (
                      dayjs(refCalendar.current?.activeStartDate).format(
                        'MMMM'
                      ) === 'January'
                    ) {
                      refCalendar.current?.setActiveStartDate(
                        dayjs(refCalendar.current?.activeStartDate)
                          .set('month', 11)
                          .toDate()
                      )
                      setActiveMonth(
                        dayjs(refCalendar.current?.activeStartDate)
                          .set('month', 11)
                          .locale(language)
                          .format('MMMM')
                      )
                    } else {
                      refCalendar.current?.setActiveStartDate(
                        dayjs(refCalendar.current?.activeStartDate)
                          .subtract(1, 'month')
                          .toDate()
                      )
                      setActiveMonth(
                        dayjs(refCalendar.current?.activeStartDate)
                          .subtract(1, 'month')
                          .locale(language)
                          .format('MMMM')
                      )
                    }
                  }}
                />
              </div>
              <div className={styles.Month}>
                {activeMonth}
                {/* {activeDay && dayjs(activeDay).locale(language).format('MMMM')} */}
              </div>
              <div className="ms-5">
                <img
                  src={rightCaret}
                  alt="left"
                  onClick={() => {
                    // if current month is december, go to january of the same year
                    if (
                      dayjs(refCalendar.current?.activeStartDate).format(
                        'MMMM'
                      ) === 'December'
                    ) {
                      refCalendar.current?.setActiveStartDate(
                        dayjs(refCalendar.current?.activeStartDate)
                          .set('month', 0)
                          .toDate()
                      )
                      setActiveMonth(
                        dayjs(refCalendar.current?.activeStartDate)
                          .set('month', 0)
                          .locale(language)
                          .format('MMMM')
                      )
                    } else {
                      refCalendar.current?.setActiveStartDate(
                        dayjs(refCalendar.current?.activeStartDate)
                          .add(1, 'month')
                          .toDate()
                      )
                      setActiveMonth(
                        dayjs(refCalendar.current?.activeStartDate)
                          .add(1, 'month')
                          .locale(language)
                          .format('MMMM')
                      )
                    }
                  }}
                  className="pointer"
                />
              </div>
            </div>
            <div>
              <div
                className={styles.InfoLabel}
                onClick={() => {
                  setShowInfo(!showInfo)
                }}
              >
                {showInfo ? t('chiudi') : t('info')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ContainerClips}>
          <ReactCalendar
            ref={refCalendar}
            defaultActiveStartDate={defaultActiveDate}
            defaultView={'month'}
            view={'month'}
            className={styles.Calendar}
            tileContent={({ activeStartDate, date, view }) => {
              const dateFormat = dayjs(date).format('DD/MM/YYYY')
              if (!clipsByDay[dateFormat]) return <div></div>
              const clip = clipsByDay[dateFormat][0]
              return (
                <LangLink
                  key={clip.id}
                  className="no-link"
                  to={`/almanac/${smartSlug(
                    clip.id,
                    clip.document.title[i18n.language]
                  )}`}
                >
                  <div
                    onMouseEnter={() => {
                      setActiveClip(clip)
                    }}
                    onMouseLeave={() => {
                      setActiveClip(null)
                    }}
                  >
                    <div className={styles.YearClip}>
                      {dayjs(clip.document.date).format('YYYY')}
                    </div>
                    <div className="d-flex">
                      <div className={styles.TitleClip}>
                        {clip.document.title[i18n.language]}
                      </div>
                    </div>
                    {activeClip?.id === clip.id && (
                      <div
                        className={styles.ImageClip}
                        style={{
                          backgroundImage: `url(${clip.document.image_thumb})`,
                        }}
                      >
                        <div className={styles.DateClip}>
                          {dayjs(clip.date).format('D')}
                        </div>
                      </div>
                    )}
                  </div>
                </LangLink>
              )
            }}
            minDate={dayjs(startDate).toDate()}
            maxDate={dayjs(endDate).toDate()}
            showFixedNumberOfWeeks={true}
            locale={language}
          />
        </div>
      </div>
      <Offcanvas
        direction="end"
        className={styles.OffCanvasInfo}
        isOpen={showInfo}
        backdropClassName="offcanvas-backdrop-info"
        toggle={() => {
          setShowInfo(!showInfo)
        }}
      >
        <OffcanvasBody
          style={{
            backgroundColor: 'var(--tertiary-100)',
          }}
        >
          <div className="text-md text-100-primary">{t('informazioni')}</div>
          <div
            className="text-sm mt-2"
            dangerouslySetInnerHTML={{
              __html: textAlmanacco.text[i18n.language],
            }}
          ></div>
        </OffcanvasBody>
      </Offcanvas>
    </Layout>
  )
}
