import { useEffect, useRef, useState } from 'react'
import { ReactComponent as PlayIcon } from '../../assets/play.svg'
import { useTranslation } from 'react-i18next'
import { CalendarDocument } from '../../types'
import styles from './FullClipOfTheDay.module.css'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import closeClip from '../../assets/close-white.svg'

interface FullClipOfTheDayProps {
  calendar: CalendarDocument
  showBack?: boolean
  isDetailAlmanac?: boolean
  fromHome?: boolean
}

export default function FullClipOfTheDay({
  calendar,
  fromHome,
}: FullClipOfTheDayProps) {
  const { i18n } = useTranslation()

  const videoRef = useRef<any>()
  const [paused, setPaused] = useState(true)
  const [ended, setEnded] = useState(false)
  const [showPreview, setShowPreview] = useState(true)
  const navigate = useNavigate()

  const handlePlayerPause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setPaused(true)
    }
  }

  const handlePlayerPlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setPaused(false)
      setShowPreview(false)
    }
  }

  useEffect(() => {
    if (fromHome) {
      handlePlayerPlay()
    }
  }, [fromHome])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('contextmenu', (e: any) => {
        e.preventDefault()
      })
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('contextmenu', (e: any) => {
          e.preventDefault()
        })
      }
    }
  }, [videoRef])

  return (
    <div className={styles.VimeoContainer}>
      <div
        className={styles.CloseVideo}
        onClick={() => {
          if (fromHome) {
            navigate('/' + i18n.language)
          } else {
            navigate('/' + i18n.language + '/almanac')
          }
        }}
      >
        <img src={closeClip} alt="close" />
      </div>
      <div
        className={classNames(`${styles.Preview}`, {
          'd-none': !showPreview,
        })}
        style={{ backgroundImage: `url(${calendar.document.image_medium})` }}
      >
        <div className={`${styles.PlayIcon}`} onClick={handlePlayerPlay}>
          <PlayIcon />
        </div>
      </div>
      <video
        controlsList="nodownload"
        onEnded={() => {
          handlePlayerPause()
          setEnded(true)
        }}
        onClick={() => {
          if (paused) {
            handlePlayerPlay()
          } else {
            handlePlayerPause()
          }
        }}
        autoPlay={fromHome ? true : false}
        ref={videoRef}
        className={classNames(`${styles.Video}`, {
          'd-none': showPreview,
        })}
        src={calendar.document.url_content || ''}
        width={'100%'}
        controls={paused === false ? true : false}
      ></video>
      {paused && !ended && !showPreview && (
        <div
          className={`${styles.PlayIconAfterPause}`}
          onClick={handlePlayerPlay}
        >
          <PlayIcon />
        </div>
      )}
    </div>
  )
}
