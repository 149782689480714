import classNames from 'classnames'
import { ReactNode } from 'react'
import { useIsMobileScreen } from '../hooks/screen'
import TopBar from './TopBar'

interface LayoutProps {
  className?: string
  children: ReactNode
  noTopbar?: boolean
  title?: string
  right?: ReactNode
  left?: ReactNode
  linkStories?: boolean
}

export default function Layout({
  children,
  title,
  className = '',
  noTopbar = false,
  linkStories = false,
}: LayoutProps) {
  const isMobile = useIsMobileScreen()
  return (
    <>
      {<TopBar linkStories={linkStories} noTopbar={noTopbar} title={title} />}
      <div
        className={classNames(`h-100 ${className}`, {
          'padding-top-bar': !noTopbar || isMobile,
        })}
      >
        {children}
      </div>
    </>
  )
}
