import { Theme } from '../../types'
import bgClipInfo from '../../assets/shape-clip-home.svg'
import { ReactComponent as PlayIcon } from '../../assets/play.svg'
import styles from '../FullPreviewClip/FullPreviewClip.module.css'
import { useTranslation } from 'react-i18next'
import aboutImg from '../../assets/images/about-page.png'
import classNames from 'classnames'
import { useContent } from '../../hooks/contents'
import LangLink from '../LangLink'

interface FullPreviewClipProps {
  tema?: Theme
}

export default function FullSerieHome({ tema }: FullPreviewClipProps) {
  const { i18n, t } = useTranslation()
  const aboutText = useContent('testo-pagina-team-e-contatti')
  return (
    <>
      <div
        className={classNames(styles.FullPreviewClip, {
          pointer: tema,
        })}
        style={{
          backgroundImage: `url(${
            tema ? tema.cover_image : aboutImg
          })`,
        }}
      >
        {tema && (
          <div className={styles.PlayIcon}>
            <PlayIcon />
          </div>
        )}
        <div
          className={styles.BlockInfoClip}
          style={{
            backgroundImage: `url(${bgClipInfo})`,
          }}
        >
          {tema && (
            <div className={styles.ContainerFilmDelGiorno}>
              <LangLink to={'/stories'} className="no-link">
                <div className={styles.Almanacco}>{t('themes_page_title')}</div>
              </LangLink>
             
            </div>
          )}
          <div>
            
            <div
              style={{}}
              className={classNames(styles.TitleClip, {
                [styles.MarginTop150]: !tema,
              })}
            >
              {tema
                ? tema.title[i18n.language]
                : aboutText.title[i18n.language]}
            </div>
          </div>
          {/* {calendar && <div className={styles.GoToClip}>{t('go_to_clip')}</div>} */}
        </div>
        
      </div>
      <div className={styles.BlockMobile}>
        <div className={classNames(styles.TitleClip)}>
          {tema ? tema.title[i18n.language] : aboutText.title[i18n.language]}
        </div>
        {/* {calendar && <div className={styles.GoToClip}>{t('go_to_clip')}</div>} */}
      </div>
    </>
  )
}
